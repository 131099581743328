import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SalesService } from '../../sales.service';
import { CommonService } from 'src/app/shared/common.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UsersService } from 'src/app/mods/users/users.service';
import { MessageService } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sales-clientes-create',
  templateUrl: './sales-clientes-create.component.html',
  styleUrls: ['./sales-clientes-create.component.css'],
  providers: [MessageService]
})
export class SalesClientesCreateComponent implements OnInit {
  
  private destroy$ = new Subject();
  form: FormGroup = this.fb.group({});
  asistentes : any[] = [];

  constructor(
    public config: DynamicDialogConfig, 
    public ref: DynamicDialogRef,
    private fb: FormBuilder,
    private salesServices: SalesService,
    public commonService: CommonService,
    public userService: UsersService,
    public messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.getAsistentes()
    this.form = this.fb.group({
      nombre: '',
      apellido:  '',
      email: ['', [Validators.email]],
      email_2: ['', [Validators.email]],
      phone:  null,
      phone_2 : null,
      main_asist:  null,
    });
  }

  getAsistentes(){
    this.salesServices.getAsistentes().pipe(takeUntil(this.destroy$)).subscribe(
      (asistentes) => {
        this.asistentes = asistentes
      })
  }
  
  onSubmit() {
    if (!this.form.get('nombre')?.value || !this.form.get('apellido')?.value) {
      this.commonService.showWarningMessage('The first name and last name are required.');
      return;
    }
      let data = this.form.value;
      this.createCliente(data);
  }

  createCliente(data: any) {
    console.log(data)
    this.salesServices.createClient(data).pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.commonService.showSuccessMessage(response.msg);
      this.ref.close({updateTable: true});
    }, error => {
      this.commonService.showErrorMessage(error);
    });
  }

  cancel() {
    this.form.reset();
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
