import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SalesAddressService } from '../../sales-address.service';
import { CommonService } from 'src/app/shared/common.service';
import { casa_v2, condominio, desarrollo, edificio, villa } from '../../sales-search/address.enum';

@Component({
  selector: 'app-sales-config-address-condominios',
  templateUrl: './sales-config-address-condominios.component.html',
  styleUrls: ['./sales-config-address-condominios.component.css']
})
export class SalesConfigAddressCondominiosComponent implements OnInit {
@ViewChild('desarrollo',{static:false})desarrolloInput!: ElementRef

  private destroy$ = new Subject<void>();

  loading = true

  desarrollos: desarrollo[] = []
  edificios: edificio[] = []
  condominios: condominio[] = []
  villas: villa[] = []
  casas: casa_v2[] = []

  //Filtros
  FiteredDesarrollos: desarrollo[] = []
  FilteredEdificios: edificio[] = []
  FilteredCondominios: condominio[] = []
  FilteredVillas: villa[] = []
  FilteredCasas: casa_v2[] = []

  //Filtros para Activos
  desarrollosActivos: desarrollo[] = []
  edificiosActivos: edificio[] = []
  condominiosActivos: condominio[] = []

  //Forms
  addEdificioForm: FormGroup = this.fb.group({})
  addCondominioForm: FormGroup = this.fb.group({})
  addRecidencialForm: FormGroup = this.fb.group({})
  addVillaForm: FormGroup = this.fb.group({})
  addCasaForm: FormGroup = this.fb.group({})

  //Filters
  desarrolloFilter: number = 0
  edificioFilter: number = 0

  //Edit
  //Desarrollo
  editDesarrolloId: number = 0
  //Edificio 
  editEdificioId: number = 0
  //Condominio
  editCondominioId: number = 0
  //Recidencial
  editRecidencialId: number = 0
  //villa
  editVillaId: number = 0
  //casa
  editCasaId: number = 0

  //Utils
  tempName: string = ''
  tempVar: number = 0
  constructor(
    private fb: FormBuilder,
    private addressService:SalesAddressService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    //Cargas inciales
    this.getDesarrollos()
    this.getEdificios()
    this.getCondominios()
    this.getVillas()
    this.getCasas()

    this.addEdificioForm = this.fb.group({
      selectedDesarrollo: [ null, Validators.required],
      edificioNombre: ['', Validators.required]
    });
    
    this.addCondominioForm = this.fb.group({
      edificio: [null, Validators.required],
      condominio: ['', Validators.required],
      habitaciones: [null, Validators.required]
    })

    this.addRecidencialForm = this.fb.group({
      nombre:['', Validators.required],
      tipo:['', Validators.required]
    })

    this.addVillaForm = this.fb.group({
      desarrollo:[null, Validators.required],
      villa:['', Validators.required]
    })

    this.addCasaForm = this.fb.group({
      desarrollo:[null, Validators.required],
      casa:['', Validators.required]
    })

    this.loading = false
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //toggle
  onToggleChange(event: any, desarrollo: desarrollo) {
    const previousStatus = desarrollo.status
  
    desarrollo.status = event.checked ? "Activo" : "Inactivo"
  
    const data = {
      id: desarrollo.id,
      value: desarrollo.status
    };
  
    this.addressService.update("status" ,data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
      },
      err => {
        this.commonService.showErrorMessage(err)
        desarrollo.status = previousStatus;
  
        event.source.checked = previousStatus === "Activo"
      }
    );
  }
  
  //Desarrollos

  getDesarrollos(){
    this.addressService.get("desarrollo").pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.desarrollos = res
        this.filterStatusDesarrollo()
      }, err => {
        this.commonService.showWarningMessage(err)
      }
    )
  }

  filterStatusDesarrollo() {
    this.desarrollosActivos = []  
    this.desarrollosActivos = this.desarrollos.filter(desarrolloActivo => desarrolloActivo.status === 'Activo')
  }
  
  addDesarollo(value: string){
    if (value && value.trim()) {
      this.addressService.add("desarrollo", value).subscribe(
        res =>{
          this.commonService.showSuccessMessage(res.msg)
          this.desarrolloInput.nativeElement.value = '';
          this.getDesarrollos()
        }, err => {
          this.commonService.showErrorMessage(err)
        }
      )
    }
  }

  OnEditDesarrollo(desarrollo: desarrollo){
    this.editDesarrolloId = desarrollo.id
    this.tempName = desarrollo.nombre
  }

  editDesarrollo(desarrollo: desarrollo){
    if(!desarrollo.nombre.trim()){
      this.commonService.showInfoMessage('El nombre del desarrollo no puede esta vacio')
      return
    }

    if(desarrollo.nombre === this.tempName){
      this.commonService.showInfoMessage("El nuevo desarrollo no puede ser igual al anterior")
      return
    }

    const data = {
      id: desarrollo.id,
      value: desarrollo.nombre
    }

    this.addressService.update("nombre", data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonService.showSuccessMessage(res.msg)

        for(let desarrollo of this.desarrollos){
          if(desarrollo.id === data.id){
            desarrollo.nombre = data.value
          }
        }

        this.editDesarrolloId = 0
        this.tempName = ''
      }, err => {
        this.commonService.showErrorMessage(err)
      }
    ) 
  }

  cancelarEdicionDesarrollo(){
    this.editDesarrolloId = 0
    this.tempName = ''
  }

  OnSelectDesarrollo(id: number) {
    this.FilteredEdificios = this.edificios.filter(edificio => edificio.id_desarrollo === id);
  }
  

  //Edificios 

  getEdificios(){
    this.edificios = []
    this.addressService.getEdificios().pipe(takeUntil(this.destroy$)).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.edificios = res
        this.filterStatusEdificios()
      }, err => {
        this.commonService.showWarningMessage(err)
      }
    )
  }

  filterStatusEdificios(){
    this.edificiosActivos = []
    this.edificiosActivos = this.edificios.filter(edificioActivo => edificioActivo.status === "Activo")
  }

  addEdificio(){
    if (this.addEdificioForm.valid){
      this.addressService.addEdificio(this.addEdificioForm.value).pipe(takeUntil(this.destroy$)).subscribe(
        res =>{
          this.commonService.showSuccessMessage(res.msg)
          this.addEdificioForm.reset({
            selectedDesarrollo: null,  
            edificioNombre: ''        
          });
          this.getEdificios()
        }, err => {
          this.commonService.showErrorMessage(err)
        }
      )
    }
  }

  OnEditEdificio(values: any){
    this.editEdificioId = values.id_edificio
    this.tempName = values.nombre_edificio
  }
  
  editEdificio(values: any){
    const data = {
      value: values.nombre_edificio,
      id: values.id_edificio
    }

    this.addressService.editEdificio(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonService.showSuccessMessage(res.msg)

        for(let edificio of this.edificios){
          if(edificio.id_edificio === data.id){
            edificio.nombre_edificio = data.value
          }
        }

        this.editEdificioId = 0
        this.tempName = ''
        this.getEdificios()
      }, err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  cancelarEdicionEdificio(){
    this.editEdificioId = 0
    this.tempName = ''
  }

  changeStatusEdificio(event:any, edificio: edificio){

    const previousStatus = edificio.status
    
    edificio.status = event.checked ? "Activo" : "Inactivo"

    const data = {
      id: edificio.id_edificio, 
      status: edificio.status
    }

    this.addressService.statusEdificio(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
      }, err => {
        this.commonService.showErrorMessage(err)
        edificio.status = previousStatus
        event.source.checked = previousStatus === "Activo"
      }
    )
  }

  OnSelectEdificio(id: number){
    this.FilteredCondominios = this.condominios.filter(condominio => condominio.id_edificio === id);
  }

  //habitaciones

  getCondominios(){
    this.condominios = []
    this.addressService.getCondominios().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.condominios = res
        this.filterStatusCondominios()
      }, err => {
        this.commonService.showWarningMessage(err)
      }
    )
  }

  filterStatusCondominios(){
    this.condominiosActivos = []
    this.condominiosActivos = this.condominios.filter(condominiosActivo => condominiosActivo.status === "Activo")
  }

  addCondominio(){
    if ( this.addCondominioForm.valid){
      this.addressService.addCondominio(this.addCondominioForm.value).pipe(takeUntil(this.destroy$)).subscribe(
        res =>{
          this.commonService.showSuccessMessage(res.msg)
          this.addCondominioForm.reset({
            selectedEdificio: null,
            habitacionNumero: ''
          })
          this.getCondominios()
        }, err => {
          this.commonService.showErrorMessage(err)
        }
      )
    }
  }

  OnEditCondominio(values: condominio){
    this.editCondominioId = values.id
    this.tempName = values.nombre_condominio
  }

  editCondominio(condominio: condominio){
    const data = {
      nombre: condominio.nombre_condominio,
      habitaciones: condominio.numero_de_habitaciones,
      id: condominio.id
    }

    this.addressService.editCondominio(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonService.showSuccessMessage(res.msg)
        this.getCondominios()
        this.editCondominioId = 0
        this.tempName = ''

      }, err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  cancelarEdicionCondominio(){
    this.editCondominioId = 0
    this.tempName = ''
  }

  changeStatusCondominio(event: any, condominio: condominio){

    const previousStatus = condominio.status
  
    condominio.status = event.checked ? "Activo" : "Inactivo"

    const data = {
      id: condominio.id,
      status: condominio.status
    }

    this.addressService.statusCondominio(data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
      }, err => {
        this.commonService.showErrorMessage(err)
        condominio.status = previousStatus
  
        event.source.checked = previousStatus === "Activo"
      }
    )
  }
 
  //viilas
  addVilla(){
    if(this.addVillaForm.valid){
      const data = {
        desarrollo: this.addVillaForm.value.desarrollo,
        villa: this.addVillaForm.value.villa
      }

      this.addressService.addVilla(data).pipe(takeUntil(this.destroy$)).subscribe(
        res => {
          this.commonService.showSuccessMessage(res.msg)
          this.addVillaForm.reset({
            desarrollo: null,
            villa: ""
          })
          this.getVillas()
        }, err =>{
         this.commonService.showErrorMessage(err) 
        }
      )
    }
  }

  getVillas(){
    this.villas = []
    this.addressService.getVillas().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.villas = res
      }, err =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  OnSelectDesarrolloVilla(id: number){
    this.FilteredVillas = this.villas.filter(villa => villa.desarrollo === id)
  }

  OnEditVilla(villa: villa){
    this.editVillaId =  villa.id
    this.tempName = villa.villa
  }

  editVilla(villa: villa){
    if(!villa.villa.trim()){
      this.commonService.showInfoMessage('El nombre de la villa no puede estar vacio')
      return
    }

    this.addressService.editVilla(villa).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonService.showSuccessMessage(res.msg)

        this.editVillaId = 0
        this.tempName = ""
        this.getVillas()
      }, err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  cancelarEdicionVilla(){
    this.editVillaId = 0
    this.tempName = ""
  }

  changeStatusVilla(event: any, villa: villa){
    const previusStatus = villa.status

    villa.status = event.checked ? "Activo" : "Inactivo"

    this.addressService.editVilla(villa).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
      }, err =>{
        this.commonService.showErrorMessage(err)
        villa.status = previusStatus
        event.source.checked = previusStatus === "Activo"
      }
    )
  }

  //casas
  addCasa(){
    if(this.addCasaForm.valid){
      const data = {
        desarrollo: this.addCasaForm.value.desarrollo,
        casa: this.addCasaForm.value.casa
      }

      this.addressService.addCasa(data).pipe(takeUntil(this.destroy$)).subscribe(
        res => {
          this.commonService.showSuccessMessage(res.msg)
          this.addCasaForm.reset({
            desarrollo: null,
            casa: ""
          })
          this.getCasas()
        }, err =>{
         this.commonService.showErrorMessage(err) 
        }
      )
    }
  }

  getCasas(){
    this.casas = []
    this.addressService.getCasas().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.casas = res
      }, err =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  OnSelectDesarrolloCasa(id: number){
    this.FilteredCasas = this.casas.filter(casa => casa.desarrollo === id)
  }

  OnEditCasa(casa: casa_v2){
    this.editCasaId =  casa.id
    this.tempName = casa.casa
  }

  editCasa(casa: casa_v2){
    if(!casa.casa.trim()){
      this.commonService.showInfoMessage('El nombre de la casa no puede estar vacio')
      return
    }

    this.addressService.editCasa(casa).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonService.showSuccessMessage(res.msg)

        this.editCasaId = 0
        this.tempName = ""
        this.getCasas()
      }, err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  cancelarEdicionCasa(){
    this.editCasaId = 0
    this.tempName = ""
  }

  changeStatusCasa(event: any, casa: casa_v2){
    const previusStatus = casa.status

    casa.status = event.checked ? "Activo" : "Inactivo"

    this.addressService.editCasa(casa).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
      }, err =>{
        this.commonService.showErrorMessage(err)
        casa.status = previusStatus
        event.source.checked = previusStatus === "Activo"
      }
    )
  }
}
