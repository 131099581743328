import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';
import { SalesAddressService } from '../../../sales-address.service';
import { takeUntil } from 'rxjs/operators';
import { casa, casa_v2, condominio, desarrollo, edificio, villa } from '../../../sales-search/address.enum';

@Component({
  selector: 'app-sales-clientes-address-desarrollo',
  templateUrl: './sales-clientes-address-desarrollo.component.html',
  styleUrls: ['./sales-clientes-address-desarrollo.component.css']
})
export class SalesClientesAddressDesarrolloComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();

  propiedad = ""

  VillaForm: FormGroup = this.fb.group({})
  AddressForm: FormGroup = this.fb.group({})
  CasaForm: FormGroup = this.fb.group({})

  desarrollos: desarrollo[] = [];
  edificios: edificio[] = [];
  condominios: condominio[] = [];
  villas: villa[] = [];
  casas: casa_v2[] = []

  edificiosFilter: edificio[] = [];
  villasFilter: villa[] = [];
  casasFilter: casa_v2[] = [];
  condominiosFilter: condominio[] = [];

  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    public commonService: CommonService,
    public addresService: SalesAddressService,
    public ref: DynamicDialogRef,
  ) {
    this.AddressForm = this.fb.group({
      desarrollo: [null, Validators.required],
      edificio: [null, Validators.required],
      condominio: [null, Validators.required],
      habitacion: [0],
    });

    this.VillaForm = this.fb.group({
      desarrollo: [null, Validators.required],
      villa: [null, Validators.required]
    })

    this.CasaForm = this.fb.group({
      desarrollo: [null, Validators.required],
      casa: [null, Validators.required]
    })
  }

  ngOnInit(): void {
    this.getDesarrollos();
    this.getEdificios();
    this.getCondominios();
    this.getVillas();
    this.getCasas();
    
    this.AddressForm.get('desarrollo')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((desarrolloId) => {
      this.edificiosFilter = this.edificios.filter(e => e.id_desarrollo === desarrolloId);
      this.AddressForm.get('edificio')?.reset();
      this.AddressForm.get('edificio')?.enable();
      this.AddressForm.get('condominio')?.reset();
      this.AddressForm.get('condominio')?.disable();
      this.AddressForm.get('habitacion')?.reset();
      this.AddressForm.get('habitacion')?.disable();
    });

    this.AddressForm.get('edificio')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((edificioId) => {
      this.condominiosFilter = this.condominios.filter(h => h.id_edificio === edificioId);
      this.AddressForm.get('condominio')?.reset();
      this.AddressForm.get('condominio')?.enable();
      this.AddressForm.get('habitacion')?.reset();
      this.AddressForm.get('habitacion')?.disable();
    });

    this.AddressForm.get('condominio')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.condominiosFilter = this._filterCondominio(value, this.condominios)
      this.AddressForm.get('habitacion')?.reset();
      this.AddressForm.get('habitacion')?.enable();
    })

    this.VillaForm.get('villa')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.villasFilter = this._filterVilla(value, this.villas)
    })

    this.CasaForm.get('casa')?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(value => {
      this.casasFilter = this._filterCasa(value, this.casas)
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getVillas(){
    this.addresService.getVillas().pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        this.villas = res.filter((v: villa) => v.status === "Activo")
      },err =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  getDesarrollos() {
    this.addresService.get("desarrollo").pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        this.desarrollos = res.filter((d: desarrollo) => d.status === "Activo")
      },
      err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  getEdificios() {
    this.addresService.getEdificios().pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        this.edificios = res.filter((e:edificio)=> e.status === "Activo")
      },
      err => {
        this.commonService.showErrorMessage(err);
      }
    );
  }

  getCondominios() {
    this.addresService.getCondominios().pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        this.condominios = res.filter((c: condominio)=> c.status === "Activo")
      },
      err => {
        this.commonService.showErrorMessage(err);
      }
    );
  }

  getCasas(){
    this.addresService.getCasas().pipe(takeUntil(this.unsubscribe$)).subscribe(
      res => {
        this.casas = res.filter((c: casa)=> c.status === "Activo")
      }, 
      err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }
  onSubmit() {
    const data = { 
      address : this.AddressForm.value,
      cliente: this.config.data 
    }
    this.addresService.addAddresDesarrollo(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
      res =>{
        this.commonService.showSuccessMessage(res.msg)
        this.ref.close(true)
      }, err =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  onSubmitVilla(){
    const data = {
      address: this.VillaForm.value,
      cliente: this.config.data
    }
    this.addresService.addVillaToCliente(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
      res =>{
        this.commonService.showSuccessMessage(res.msg)
        this.ref.close(true);
      }, err =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  onSubmitCasa(){
    const data = {
      address: this.CasaForm.value,
      cliente: this.config.data
    }
    this.addresService.addCasaToCliente(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
      res =>{
        this.commonService.showSuccessMessage(res.msg)
        this.ref.close(true);
      }, err =>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  onOptionSelected(event: any): void {  
    const selectedCondo = this.condominios.find(
      (condo) => condo.nombre_condominio === this.AddressForm.get('condominio')?.value
    )
  
    if (selectedCondo) {
      const habitaciones = selectedCondo.numero_de_habitaciones ?? 0
      this.AddressForm.patchValue({ habitacion: habitaciones })
    }
  }  

  _filterCondominio(value: string, options: condominio[]):condominio[]{
    if(!value || value === null){
      return []
    }
    const filterValue = value.toLowerCase().toString()
    return options.filter(option => option.nombre_condominio.toLowerCase().includes(filterValue) && option.status === "Activo" && option.id_edificio === this.AddressForm.value.edificio)
  }

  _filterVilla(value: string, options: villa[]):villa[]{
    const filterValue = value.toLowerCase().toString()
    return options.filter(option => option.villa.toLowerCase().includes(filterValue) && option.status === "Activo" && option.desarrollo === this.VillaForm.value.desarrollo)
  }
  
  _filterCasa(value: string, options: casa_v2[]): casa_v2[]{
    const filterValue = value.toLowerCase().toString()
    return options.filter(option => option.casa.toLowerCase().includes(filterValue) && option.status === "Activo" && option.desarrollo === this.CasaForm.value.desarrollo)
  }

  OnSelectDesarrolloVilla(){
    this.villasFilter = []
    this.villasFilter = this.villas.filter(villa => villa.desarrollo === this.VillaForm.value.desarrollo && villa.status === "Activo")
  }

  OnSelectDesarrolloCasa(){
    this.casasFilter = []
    this.casasFilter = this.casas.filter(casa => casa.desarrollo === this.CasaForm.value.desarrollo && casa.status === "Activo")
  }
}
