import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { faCalendarWeek, faHistory, faScaleBalanced, faSignOutAlt, faTachometerAlt, faUsersCog, faTags, faExchangeAlt, faCashRegister } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../shared/common.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [MessageService]
})
export class HomeComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject();
  dashboardIcon = faTachometerAlt;
  signOutIcon = faSignOutAlt;
  usersIcon = faUsersCog;
  historyIcon = faHistory;
  arqueoIcon = faScaleBalanced;
  rtmIcon = faCalendarWeek;
  tagsIcon = faTags;
  ventasIcon = faCashRegister
  pricesIcon = faExchangeAlt;
  fullSize = false;

  modulesAccess = {
    panel: false,
    arqueo: false,
    rtm: false,
    users: false,
    history: false,
    tags:false,
    prices:false,
    sales:false
  }

  constructor(
    public authService: AuthService, 
    private router: Router, 
    public commonService: CommonService, 
    private messageService: MessageService
  ) {
    this.commonService.currentStore$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(store => {
      if (this.commonService.logos[store]) {
        this.commonService.currentLogo = this.commonService.logos[store];
      }
    });
  }

  ngOnInit(): void {
    this.setModulesAccess();
    this.commonService.messageServiceRef = this.messageService;
    //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    if (this.authService.loggedIn) {
      if (this.router.url === "/") {
        if (this.modulesAccess.panel) {
          this.router.navigate(["/panel"]);
        } else if (this.modulesAccess.arqueo) {
          this.router.navigate(["/arqueo"]);
        } else if (this.modulesAccess.rtm) {
          this.router.navigate(["/agenda"]);
        } else if (this.modulesAccess.history) {
          this.router.navigate(["/historial"]);
        } else if (this.modulesAccess.users) {
          this.router.navigate(["/usuarios"]);
        } else if (this.modulesAccess.tags){
          this.router.navigate(["/tags"]);
        } else if (this.modulesAccess.prices){
          this.router.navigate(["/precios"]);
        } else if (this.modulesAccess.sales){
          this.router.navigate(["/ventas"]);
        }
      }
    } else {
      this.router.navigate(["/login"]);
    }

    this.commonService.fullSize$.subscribe(fullSize => {
      this.fullSize = fullSize;
    });
  }

  setModulesAccess() {
    const access = this.authService.user.value?.access || [];
    //console.log('validating access', access);
    this.modulesAccess.panel = access.includes("panel:view");
    this.modulesAccess.arqueo = access.includes("arqueo:sales:view") || 
      access.includes("arqueo:saf:view") || 
      access.includes("arqueo:clients:view") || 
      access.includes("arqueo:fondo:view");
    this.modulesAccess.rtm = access.includes("agenda:view");
    this.modulesAccess.tags = access.includes("tags:view")
    this.modulesAccess.users = access.includes("users:view");
    this.modulesAccess.history = access.includes("history:view");
    this.modulesAccess.prices = access.includes("prices:view");
    this.modulesAccess.sales = access.includes("sales:view");

  }

  onLogout(){
    this.authService.logout();
    this.router.navigate(["/login"]);
  }

  redirectTo(uri:string){
    //this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> this.router.navigate([uri]));
    this.router.navigateByUrl('/dummy', {skipLocationChange: true}).then(()=>{
      //this.router.navigate([uri], {queryParams: {year: this.selectedYear}, relativeTo: this.route});
      this.router.navigate([uri]);
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
