import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAddressBook, faAngleDoubleLeft, faAngleDoubleRight, faCashRegister, faChartBar, faComment, faFileInvoiceDollar, faGears, faMapPin, faToolbox, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css', '../../shared/scss/structure.columns.scss']
})
export class SalesComponent implements OnInit {

  private destroy$ = new Subject();
  showTools = false;
  faSales = faCashRegister;
  faExpand = faAngleDoubleRight;
  faCollapse = faAngleDoubleLeft;
  faClientes = faAddressBook;
  faReportes = faChartBar;
  faInvoices = faFileInvoiceDollar;
  faConfig = faToolbox;
  faChats = faComment;
  faAddreses = faMapPin;
  faGears = faGears
  faSearch = faSearch

  viewSettings = {
    menuCollapsed: false
  }
  
  featureAccess = {
    sales: false,
    clientes: false,
    search: false,
    invoices: false,
    reportes: false,
    config: false,
    chats: false,
    address: false,
    addresConfig: false
  }
  fullSize: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    public alertService: AlertService,
    private commonService: CommonService
  ) { 
    this.commonService.fullSize$.pipe(
    takeUntil(this.destroy$)
  ).pipe(takeUntil(this.destroy$)).subscribe(fullSize => {
    this.fullSize = fullSize;
  })
}

  ngOnInit(): void {
    this.setFeatureAccess();
    this.redirectToFeaturePage();
  }

  setFeatureAccess() {
    const access = this.authService.user.value?.access || [];
    this.featureAccess.sales = access.includes("sales:view");
    this.featureAccess.search = access.includes("sales:view");
    this.featureAccess.address = access.includes("address:view");
    this.featureAccess.chats = access.includes("chats:view");
    this.featureAccess.clientes = access.includes("clientes:view");
    this.featureAccess.invoices = access.includes("invoices:view");
    this.featureAccess.reportes = access.includes("reportes:view");
    this.featureAccess.config = access.includes("config:view");
    this.featureAccess.addresConfig = access.includes("configAddress:view")
  }

  redirectToFeaturePage() {
    if (!this.featureAccess.sales) {
      this.router.navigate(['/panel']);
    } 
  }

  expandCollapseSubmenu() {
    this.viewSettings.menuCollapsed = !this.viewSettings.menuCollapsed;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
