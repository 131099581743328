import { Component, OnInit } from '@angular/core';
import { SalesAddressService } from '../../sales-address.service';
import { CommonService } from 'src/app/shared/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BaseType, calle, casa, ciudad, colonia } from '../../sales-search/address.enum';

@Component({
  selector: 'app-sales-config-address-colonias',
  templateUrl: './sales-config-address-colonias.component.html',
  styleUrls: ['./sales-config-address-colonias.component.css']
})
export class SalesConfigAddressColoniasComponent implements OnInit {

  private destroy$ = new Subject<void>()

  loading = false

  UpdateNombre = 'nombre'
  UpdateStatus = 'status'

  TipoColonia = "colonia"
  TipoCalle = "calle"
  TipoCasa = "casa"
  TipoCiudad = "ciudad"

  colonias: colonia[] = []
  calles: calle[] = []
  casas: casa[] = []
  ciudades: ciudad[] = []

  ColoniaInput = ""
  CalleInput = ""
  CasaInput = ""
  CiudadInput = ""

  coloniaId = 0
  calleId = 0
  casaId = 0
  ciudadId = 0

  TempName = ""
  constructor(
    public addressService: SalesAddressService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.loading = true
    this.get(this.TipoColonia)
    this.get(this.TipoCalle)
    this.get(this.TipoCasa)
    this.get(this.TipoCiudad)
    this.loading = false
  }

  add(tipo: string, value:string){
    if(!value || value.trim().length === 0){
      return this.commonService.showWarningMessage("El campo no puede estar vacio")
    }
    this.addressService.add(tipo, value).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.commonService.showSuccessMessage(res.msg)
        this.reset(tipo)
        this.get(tipo)
      }, err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  get(tipo:string){
    this.addressService.get(tipo).pipe(takeUntil(this.destroy$)).subscribe(
      (success)=>{
        switch(tipo){
          case this.TipoColonia:
            this.colonias = success
            break
          case this.TipoCalle:
            this.calles = success
            break
          case this.TipoCasa:
            this.casas = success
            break
          case this.TipoCiudad:
            this.ciudades = success
            break
        }
      },(err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  OnEdit(tipo: string, values: BaseType){
    switch(tipo){
      case this.TipoColonia:
        this.coloniaId = values.id
        this.TempName = values.nombre
        break
      case this.TipoCalle:
        this.calleId = values.id
        this.TempName = values.nombre
        break
      case this.TipoCasa:
        this.casaId = values.id
        this.TempName = values.nombre
        break
      case this.TipoCiudad:
        this.ciudadId = values.id
        this.TempName = values.nombre
        break
    }
  }

  Edit(tipo: string, values:BaseType, updateTipo: string){

    if(!values.nombre.trim()){
      this.commonService.showInfoMessage(`El nombre de ${tipo} no puede estar vacio`)
      return
    }

    if(values.nombre === this.TempName){
      this.commonService.showInfoMessage(`El nombre de ${tipo} no puede ser igual al anterior`)
      return
    }

    const data = {
      id: values.id,
      value: values.nombre
    }

    const updateData = (updateTipo: string, list: any[], tipo: string) => {
      this.addressService.update(updateTipo, data).pipe(takeUntil(this.destroy$)).subscribe(
        (res) => {
          this.commonService.showSuccessMessage(res.msg)
          const item = list.find((item) => item.id === data.id)
          if (item) {
            item.name = data.value;
          }
          this.reset(tipo)
        },
        (err) => {
          this.commonService.showErrorMessage(err);
        }
      )
    }

    switch(tipo){
      case this.TipoColonia:
        updateData(updateTipo, this.colonias, this.TipoColonia)
        break
      case this.TipoCalle:
        updateData(updateTipo, this.colonias, this.TipoCalle)
        break
      case this.TipoCasa:
        updateData(updateTipo, this.colonias, this.TipoCasa)
        break
      case this.TipoCiudad:
        updateData(updateTipo, this.ciudades, this.TipoCiudad)
        break
    }
  }

  onToggleChange(event: any, tipo: BaseType){
    const previusStatus = tipo.status

    tipo.status = event.checked ? "Activo" : "Inactivo"
    const data = {
      id: tipo.id,
      value: tipo.status
    }

    this.addressService.update(this.UpdateStatus, data).pipe(takeUntil(this.destroy$)).subscribe(
      res =>{

      },err=>{
        this.commonService.showErrorMessage(err)
        tipo.status = previusStatus
        event.source.checked === "Activo"
      }
    )
  }

  reset(tipo:string){
    switch(tipo){
      case this.TipoColonia:
        this.ColoniaInput = ""
        this.coloniaId = 0
        break
      case this.TipoCalle:
        this.CalleInput = ""
        this.calleId = 0
        break
      case this.TipoCasa:
        this.CasaInput = ""
        this.casaId = 0
        break
      case this.TipoCiudad:
        this.CiudadInput = ""
        this.ciudadId = 0
        break
      case 'todos':
        this.ColoniaInput = ""
        this.coloniaId = 0
        this.CalleInput = ""
        this.calleId = 0
        this.CasaInput = ""
        this.casaId = 0
        break
    }
  }
}
