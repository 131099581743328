import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sales-clientes-note-delete',
  templateUrl: './sales-clientes-note-delete.component.html',
  styleUrls: ['./sales-clientes-note-delete.component.css']
})
export class SalesClientesNoteDeleteComponent implements OnInit {

  searchSubscription?: Subscription;
    
  constructor(public dialogRef: MatDialogRef<SalesClientesNoteDeleteComponent>) {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  onNoClick(): void {
    this.dialogRef.close(false); // Cerrar el diálogo sin confirmar
  }

  onConfirm(): void {
    this.dialogRef.close(true); // Cerrar el diálogo confirmando la eliminación
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }
}
