import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';
import { SalesAddressService } from '../../../sales-address.service';
import { Cliente } from '../../../sales.interfaces';
import { SalesClientesViewComponent } from '../../sales-clientes-view/sales-clientes-view.component';
import { Subscription } from 'rxjs';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';

@Component({
  selector: 'app-sales-clientes-address-view-clientes',
  templateUrl: './sales-clientes-address-view-clientes.component.html',
  styleUrls: ['./sales-clientes-address-view-clientes.component.css']
})
export class SalesClientesAddressViewClientesComponent implements OnInit {

  @ViewChild('alert') alert!: AlertV2Component;

  clientes: Cliente[] =[]

  searchSubscription?: Subscription;
  constructor(
    public config: DynamicDialogConfig,
    public commonService: CommonService,
    public addresService: SalesAddressService,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public addressService: SalesAddressService,
    public alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.getClientesAddress()
  }

  getClientesAddress(){
    this.clientes = []
    this.addresService.getAddressClientes(this.config.data).subscribe(
      (clientes)=>{
        this.clientes =  clientes
      }, (err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  showClient(id: number, nombre: string) {
    this.dialogService.open(SalesClientesViewComponent, {
      header: `Cliente (${nombre})`,
      width: '70%',
      data: {id, nombre, button: false}
    })
  }

  OnDeleteClienteToAddress(id: number){
        const data = {
          idCliente: id,
          idAddress: this.config.data
        }

        const body = `
        <h2>¿Seguro que quiere eliminar al cliente de la dirección?</h2>
        <p>Podrá volver a asignar al cliente en cualquier otro momento</p>
        `
        const confirmationData: ConfirmationAlert = {
                title: 'Eliminar dirección del cliente',
                body: body,
                cancelLabel: "Cerrar",
                confirmLabel: 'Eliminar',
                confirmDanger: false,
                onConfirm: () => {
                  this.DeleteClienteToAddress(data)
                }
              };
        this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
      }

  DeleteClienteToAddress(data: any){
    this.addressService.DeleteAddressToCliente(data).subscribe(
      res=>{
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showSuccessMessage(res.msg)
        this.getClientesAddress()
      },err=>{
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showErrorMessage(err)
      }
    )
  }

  ngOnDestroy(): void {
    this.ref.close()
    this.searchSubscription?.unsubscribe()
  }

}
