import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { faFileCsv, faSpinner, faTags } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/shared/common.service';
import { Up4Products, Up16Products, Up80Products } from '../tags.enums';
import { TagsCSVService } from '../tags-csv.service';
import { TagsProcessProdutService } from '../tags-process-product.service';
import jsPDF from 'jspdf';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TagSleepTagsProducts } from './tag-sleep-products/tag-sleep-products.component';
import { TagSleepService } from './tag-sleep.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface pdfHolder {
  loading: boolean;
  doc: jsPDF | null;
}


@Component({
  selector: 'app-tag-sleep',
  templateUrl: './tag-sleep.component.html',
  styleUrls: ['./tag-sleep.component.css'],
  providers: [DialogService]
})
export class TagSleepComponent implements OnInit {

  private destroy$ = new Subject();

  ref: DynamicDialogRef | undefined;
  
  up4List: Up4Products[] = [];
  up80List: Up80Products[] = [];
  up16List: Up16Products[] = [];

  // Variables del formulario
  productId: string = "";
  quantityOption: string = "";
  quantityTags: number = 1;

  //Utils
  tagsIcon = faTags;
  loadingIcon = faSpinner;
  CSVicon = faFileCsv;
  loading = false;

   constructor(
     public commonService: CommonService,
     private messageService: MessageService,
     private csvReader: TagsCSVService,
     private productProcess: TagsProcessProdutService,
     private snackBar: MatSnackBar,
     private cd: ChangeDetectorRef,
     public dialogService: DialogService,
     private tagsService: TagSleepService,
   ) { }
 
   ngOnInit(): void {
    this.up4List = this.productProcess.getUp4Products()
    this.up16List = this.productProcess.getUp16Products()
    this.up80List = this.productProcess.getUp80Products()
    }

   ngOnDestroy(): void{
    this.productProcess.clearAllProducts();
   }
 
   onSizeChange() {
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }

  //PDF
  pdf_files: { [key: string]: pdfHolder } = {
    resume: {
      loading: false,
      doc: null,
    },
    daily: {
      loading: false,
      doc: null,
    },
  };
  
  //Manejo del CSV
  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.csvReader
        .parseCsv(file)
        .then((data) => {
          data.forEach((item) => {
            this.productProcess.processProduct(item);
          });
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  }

  //Imprimir Etiquetas
  OnLabelsPrint(): void{
    this.tagsService.generateLabelsPDF(
      this.up4List,
      this.up16List,
      this.up80List
    );
  }

  //Buscar por ID
  OnSearchID(): void {
    if (!this.productId || this.quantityOption === null || this.quantityTags === 0) {
      this.commonService.showWarningMessage("Por favor complete los campos");
      return;
    }
  
    this.loading = true;
  
    const ModuleName = "Sleep"
    this.productProcess.SearchID(this.productId, this.quantityOption, this.quantityTags, ModuleName, "N/A").pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.loading = false
        this.commonService.showSuccessMessage("Producto Agregado");
        // Limpiar campos
        this.productId = "";
        this.quantityOption = "";
        this.quantityTags = 1;
        this.cd.detectChanges();
      },
      error: (error) => {
        this.loading = false;
        const message = error.status === 404
          ? "ID no encontrado"
          : "Ocurrió un error, inténtelo de nuevo";
        this.snackBar.open(message, "Cerrar", {
          duration: 3000,
          verticalPosition: "top",
        });
      }
    });
  }
  
  //Mostrar productos del arreglo
  show(listType: string) {
    let headerTitle: string = '';
  
    switch (listType) {
      case 'up4':
        headerTitle = 'Etiquetas Up 4';
        break;
      case 'up16':
        headerTitle = 'Etiquetas Up 16';
        break;
      case 'up80':
        headerTitle = 'Etiquetas Up 80';
        break;
      default:
        this.snackBar.open("Tipo de lista no válido", "Cerrar", {
          duration: 3000,
          verticalPosition: "top",
        });
        return;
    }
  
    this.ref = this.dialogService.open(TagSleepTagsProducts, {
      data: {
        listType: listType
      },
      header: headerTitle,
      width: '50%'
    });
  
    /* console.log('Datos enviados al diálogo:', {
      products: dataToShow,
      listType: listType
    }); */
  }

}