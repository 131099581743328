import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faComment, faSearch, faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/shared/common.service';
import { SalesService } from '../sales.service';
import { AuthService } from 'src/app/auth/auth.service';
import { chat } from '../sales.interfaces';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sales-chats',
  templateUrl: './sales-chats.component.html',
  styleUrls: ['./sales-chats.component.scss']
})
export class SalesChatsComponent implements OnInit {
  @ViewChild('CardContainer') CardContainer!: ElementRef;
  private destroy$ = new Subject();

  searchQuery: string = ''
  chats : chat[] = []

  accessToken: string | null = null;

  faChats = faComment;
  faUsers = faUsersViewfinder;
  faSearch = faSearch;
  searchContact: string = '';
  filteredChats: any[]  = []

  featureAccess ={
    config: false,
  }

  constructor(
    public commonService: CommonService,
    public salesService: SalesService,
    public authService: AuthService,
  ) { }

  setFeatureAccess() {
    const access = this.authService.user.value?.access || [];
    this.featureAccess.config = access.includes("config:view");
  }

  ngOnInit(): void {
    this.getChats()
    this.setFeatureAccess();
  }

  onSearchChats(): void {
    const query = this.searchQuery.toLowerCase();
    this.filteredChats = this.chats.filter(chat =>
      chat.cliente.cliente_nombre.toLowerCase().includes(query) ||
      chat.cliente.cliente_correo.toLowerCase().includes(query)
    );
  }

  onScroll(){
    const scrollTop = this.CardContainer.nativeElement.scrollTop;
    if (scrollTop > 0) {
      this.CardContainer.nativeElement.classList.add('shadow');
      console.log("scroll")
    } else {
      this.CardContainer.nativeElement.classList.remove('shadow');
    }
  }

  getChats(){
    this.salesService.getChats().pipe(takeUntil(this.destroy$)).subscribe(
      res =>{
        this.chats = res
        console.log(this.chats)
        this.filteredChats = this.chats
      },err=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  onSizeChange() {
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  } 

  SingWithGoogle(){
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=303801480710-bogj1lua1b43sk1rpra0tjt1ivk88oqn.apps.googleusercontent.com&redirect_uri=http://localhost:5001/api/oauth2/callback&scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/gmail.readonly&access_type=offline&prompt=consent`;
    window.location.href = googleAuthUrl;
  }
  
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
