import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleDoubleLeft, faAngleDoubleRight, faObjectGroup, faObjectUngroup } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css', '../../shared/scss/structure.columns.scss'],
  providers: [MessageService]
})
export class PricesComponent implements OnInit {

  faPrice = faObjectUngroup;
  faPrices = faObjectGroup;
  faExpand = faAngleDoubleRight
  faCollapse = faAngleDoubleLeft

  fullSize = false
  showTools = false
  viewSettings ={
    menuCollapsed: false
  }
  featuresAcces = { 
    prices: false,
    UpdatePrice: false,
    UpdatePriceList: false,
  }

  constructor(
    private route: Router,
    private commonService: CommonService,
    public authService: AuthService,
    public alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.commonService.fullSize$.subscribe(fullSize => {this.fullSize = fullSize});
    this.setModuleAccess();
    this.redirectToModulePage();
  }

  setModuleAccess(){
    const access = this.authService.user.value?.access || [];
    this.featuresAcces.prices = access.includes('prices:view');
    this.featuresAcces.UpdatePrice = access.includes('prices:updatePrice:view');
    this.featuresAcces.UpdatePriceList = access.includes('prices:updatePriceList:view');
  }

  redirectToModulePage(){
    if(this.featuresAcces.prices){
      this.route.navigate(['/precios/Actualizar-Individual']);
    }else if(this.featuresAcces.UpdatePrice){
      this.route.navigate(['/precios/Actualizar-Individual']);
    }else if(this.featuresAcces.UpdatePriceList){
      this.route.navigate(['/precios/Actualizar-Masiva']);
    }else{
      this.route.navigate(['/panel']);
    }
  }

  expandCollapseSubmenu(){
    this.viewSettings.menuCollapsed = !this.viewSettings.menuCollapsed;
  }

  test(){
    console.log('test');
  }

}
