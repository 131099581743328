import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { faObjectUngroup } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';
import { UpdatePricesEditComponent } from './update-prices-edit/update-prices-edit.component';
import { item } from '../prices.enum';
import { Store, Stores } from 'src/app/shared/common.enums';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { PricesService } from '../prices.service';

@Component({
  selector: 'app-update-prices',
  templateUrl: './update-prices.component.html',
  styleUrls: ['./update-prices.component.css'],
  providers: [MessageService, DialogService]
})
export class UpdatePricesComponent implements OnInit {

  selectedItem: item = {} as item;

  ref: DynamicDialogRef | undefined;

  //Datos de la tabla
  store: string = "";
  id: number = 0 ;
  name: string = "";
  newPrice: string = "";
  oldPrice: string = "";
  stores = [...Stores];
  //iconos  
  faPrice = faObjectUngroup;

  //utils
  loading = false;
  filters = {
    date: new FormControl(moment()),
    dateFormated: '',
    store: Store.hds
  };

  items: item[] = [];
  
  constructor(
    public commonService: CommonService,
    public dialogService: DialogService,
    private priceService: PricesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(
  ): void {
  }

  onSizeChange(){
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }

  onSearch(){
    if(this.id == 0){
      this.commonService.showWarningMessage("Por favor ingrese un Id de producto");
      return;
    }
    this.loading = true;
    this.priceService.getPrices(this.id, this.filters.store).subscribe(
      (response) => {
        if(response && Array.isArray(response) && response.length > 0){
          response.forEach((item) =>{
            this.items.push({
              id: item.productId,
              name: item.productName,
              newPrice: item.productPrice,
              oldPrice: item.productPrice,
              differencePrice: 0,
              img: item.productImage || "https://via.placeholder.com/150",
              oldCost: item.proudctCost,
              newCost: item.proudctCost,
              differenceCost: 0,
              Store: item.productStore,
              ID_SB: item.productID_SB
            })
          });
          this.commonService.showSuccessMessage("Producto agregado correctamente");
          this.loading = false;
        }else{
          this.commonService.showWarningMessage("No se encontraron productos con el Id proporcionado");
          this.loading = false;
        }
      },
      (error) => {
        console.log(error);
        this.commonService.showErrorMessage("id no valido");
        this.loading = false;
      }
    );
  }

  deleteItem(item: item){
    const index = this.items.findIndex(i => i.id === item.id);
    if(index !== -1){
      this.items.splice(index, 1);
    }
  }

  onChangeStore(){
  this.commonService.currentStore$.next(this.filters.store);
  }

  onSelectedItem(item: any){
    this.selectedItem = item;
  }
  
  onUpdatePrices(){
    if(this.items.length == 0){
      this.commonService.showWarningMessage("No hay productos para actualizar");
      return;
    }

      this.loading = true;

      this.priceService.updatePrices(this.items, this.store).subscribe(
        (response) => {
          if(response){
            this.commonService.showSuccessMessage("Productos actualizados correctamente");
            this.loading = false;
            this.items = [];
          }else{
            this.commonService.showErrorMessage("Error al actualizar productos");
            this.loading = false;
          }
        },
        (error) => {
          console.log(error);
          this.commonService.showErrorMessage("Error al actualizar productos");
          this.loading = false;
        }
      )
  }

  show( selectedItem: any){
    //console.log("Mostrar item", selectedItem);
    this.ref = this.dialogService.open(UpdatePricesEditComponent, {
      data: { selectedItem },
      header: `Nombre: (${selectedItem.name}) ID: (${selectedItem.id})`,
      width: '50%'
    });
    
    this.ref.onClose.subscribe((updatedItem: item) => {
      if (updatedItem) {
        const index = this.items.findIndex(item => item.id === updatedItem.id);
        if (index !== -1) {
          this.items[index] = { ...updatedItem };
          this.cdr.detectChanges();
        }
      }
    });
  }
}

