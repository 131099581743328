import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, throwError } from 'rxjs';
import { Logos, Store } from './common.enums';
import { AlertV2Component } from './alert-v2/alert-v2.component';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  DEV_MODE: boolean = true;

  fullSize$ = new BehaviorSubject<boolean>(false);
  logos = Logos;
  currentLogo: string = Logos[Store.hds];
  messageServiceRef?: MessageService;
  currentStore$: BehaviorSubject<Store> = new BehaviorSubject<Store>(Store.hds);
  alertRef$ = new BehaviorSubject<AlertV2Component | undefined>(undefined);
  
  constructor(){}

  onSizeChange() {
    const option = !this.fullSize$.value;
    this.fullSize$.next(option);
  }

  getHashFromArray(arr: any[], key: string) {
    let hash: {[key: string]: any} = {};
    for (let elem of arr) {
      if (elem[key]) {
        hash[elem[key]] = elem;
      }
    }
    return hash;
  }

  getObjectKeysTotals(object: Object): number {
    return Object.keys(object).length;
  }

  getObjectKeys(object: Object): any[] {
    return Object.keys(object);
  }

  getNumberTwoDecimals(number: any) {
    let num = parseFloat(number.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    //return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num.toLocaleString();
  }

  sortByProperty(arrOfObs: any[], key: string) {
    arrOfObs.sort((a,b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    });
    return arrOfObs;
  }

  sortByPropertyDesc(arrOfObs: any[], key: string) {
    arrOfObs.sort((a,b) => {
      if (a[key] > b[key]) {
        return -1;
      }
      if (a[key] < b[key]) {
        return 1;
      }
      return 0;
    });
    return arrOfObs;
  }

  showErrorMessage(error: string, messageService?: MessageService, life: number = 3000) {
    const ms = (messageService) ? messageService : this.messageServiceRef;
    ms?.add({severity: 'error', summary: 'Error', detail: error, life: life});
  }

  showWarningMessage(msg: string, messageService?: MessageService, title: string = '', life: number = 3000) {
    const ms = (messageService) ? messageService : this.messageServiceRef;
    ms?.add({severity: 'warn', summary: title, detail: msg, life: life});
  }

  showSuccessMessage(msg: string, messageService?: MessageService, life: number = 3000) {
    const ms = (messageService) ? messageService : this.messageServiceRef;
    ms?.add({severity: 'success', summary: 'Éxito', detail: msg, life: life});
  }

  showInfoMessage(msg: string, messageService?: MessageService, title: string = '', life: number = 3000) {
    const ms = (messageService) ? messageService : this.messageServiceRef;
    ms?.add({severity: 'info', summary: title, detail: msg, life: life});
  }
  
  handleRequestError(errorRes: HttpErrorResponse) {
    //console.log("errorRes", errorRes);
    let errorMessage = "Ocurrió un error desconocido!, por favor inténtelo de nuevo";
    if(!errorRes.error && !errorRes.status){
      return throwError(errorMessage);
    }
    //errorMessage = errorRes.error.error;
    if (errorRes.status) {
      switch (errorRes.status) {
        case 404:
          errorMessage = "La acción solicitada no fue encontrada, si el error persiste contacte al administrador del sistema";
          break;
        case 401:
          errorMessage = "Su sesión ha expirado. Cierre e inicie sesión nuevamente";
          break;
        case 403:
          errorMessage = "Acceso denegado. No cuenta con los permisos necesarios.";
          break;
        case 500:
          errorMessage = errorRes.error.msg;
          break;
      }
    }
    // switch( errorRes.error.error ){
    //     case 'Invalid':
    //         errorMessage = "Invalid data!";
    //         break;
    // }
    return throwError(errorMessage);
  }
}