import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { throwError } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { TagsProcessProdutService } from '../tags-process-product.service';
import { keywordsConfig } from '../tags.enums';

@Injectable({
  providedIn: 'root'
})
export class TagsAshleyService {
  constructor(
    private commonService: CommonService,
    private keywordsService: TagsProcessProdutService
  ) {}

  handleRequestError(error: HttpErrorResponse) {
    let errorMessage = "";

    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.error(errorMessage);

    return throwError(() => new Error(errorMessage));
  }

  public async generateLabelsPDF(
    up2List: Array<any>,
    up4List: Array<any>,
    up16List: Array<any>,
    up80List: Array<any>,
    TagTemplate: string,
  ): Promise<number> {

    let up2Status = 200;
    let up4Status = 200; 
    let up16Status = 200;
    let up80Status = 200;

    if (up2List && up2List.length > 0) {
      up2Status = await this.Up2Label(up2List);
    }

    if (up4List && up4List.length > 0) {
      up4Status = await this.Up4Label(up4List, TagTemplate);
    }

    if (up16List && up16List.length > 0) {
      up16Status = await this.Up16Label(up16List, TagTemplate);
    }

    if (up80List && up80List.length > 0) {
      up80Status = await this.Up80Label(up80List);
    } 

    if (up2Status === 200 && up4Status === 200 && up16Status === 200 && up80Status === 200) {
      return 200;
    } else {
      return 500;
    }
  }

 private loadImage = (url: string) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
    });
  };

  private async Up4Label(up4List: Array<any>, TagTemplate: string) {
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

    let RedLine = "../../../../assets/Labels Img/line-png-16810.png"
    let ImgURL = "../../../../assets/Labels Img/Ashley_etiquetas.png";
    let background = "../../../../assets/Labels Img/Ashley_Clearanace.png"

    const labelWidth = 10.85;
    const labelHeight = 13.95;

    const Positions = [
      { x: 0, y: 0, imgX: 0, imgY: 0 },
      { x: labelWidth, y: 0, imgX: labelWidth, imgY: 0 },
      { x: 0, y: labelHeight, imgX: 0, imgY: labelHeight },
      { x: labelWidth, y: labelHeight, imgX: labelWidth, imgY: labelHeight },
    ];

    let labelIndex = 0;

    const addLabel = async (
      item: any,
      x: number,
      y: number,
      imgX: number,
      imgY: number
    ) => {
      if(item.TagTemplate === "Clearance"){
         //console.log(item)

         const img = await this.loadImage(background);

         const ImgWidth = 10.85;
         const ImgHeight = 13.95;
 
         const imgPaddingX = 0;
         const imgPaddingY = 0;
         const imgStartX = imgX + imgPaddingX;
         const imgStartY = imgY + imgPaddingY;
 
         const centerX = x + labelWidth / 2;
         const startY = y + 2.7;
         const lineHeight = 1;
 
         //Background
         doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);
 
         //Product Name
         doc.setFont("Helvetica", "normal");
         doc.setFontSize(18)
         let longText = item.productName;
         let textLines = doc.splitTextToSize(longText, 9); // Ancho máximo de 9 unidades
         
         // Dibujar cada línea centrada
         textLines.forEach((line:string, index: number) => {
             let textWidth = doc.getTextWidth(line);
             doc.text(line, centerX - textWidth / 2, startY + index * lineHeight);
         });
         
 
         //Product ID
         let textWidth = doc.getTextWidth(item.productId);
         doc.text(item.productId, centerX - textWidth / 2, startY + textLines.length);
 
         //Retail Price
         let Retail = item.productRetail;
         let RetailPrice: Number = +Retail;
         let formattedPrice = RetailPrice.toLocaleString("en-US", {
           minimumFractionDigits: 2,
         });
         textWidth = doc.getTextWidth(`$${formattedPrice}`);
         doc.text(
           `$${formattedPrice}`,
           centerX - textWidth / 2,
           startY + 4.94 * lineHeight + .75
         );
 
         doc.setFontSize(46);
         doc.setTextColor(237, 28, 36);
         formattedPrice = item.productPrice.toLocaleString("en-US", {
           minimumFractionDigits: 2,
         });
         textWidth = doc.getTextWidth(`$${formattedPrice}`);
         doc.text(
           `$${formattedPrice}`,
           centerX - textWidth / 2,
           startY + 7.64 * lineHeight + .30
         );
 
         doc.setTextColor(0, 0, 0);
         doc.setFontSize(30);
         let price = +item.productPrice;
         let saving = Retail - price;
           
         formattedPrice = saving.toLocaleString("en-US", {
           minimumFractionDigits: 2,
         });
         textWidth = doc.getTextWidth(`$${formattedPrice}`);
         doc.text(
           `$${formattedPrice}`,
           centerX - textWidth / 2,
           startY + 9.85 * lineHeight 
         );
        
      }else if(item.TagTemplate === "Outlet"){
      try {
        const img = await this.loadImage(ImgURL);

        const ImgWidth = 3.3;
        const ImgHeight = 2.4;

        const imgPaddingX = 3.8;
        const imgPaddingY = 1;
        const imgStartX = imgX + imgPaddingX;
        const imgStartY = imgY + imgPaddingY;

        const centerX = x + labelWidth / 2;
        const startY = y + 4;
        const lineHeight = 1;

        //Logo
        doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

        //Product Name
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(22)
        let longText = item.productName;
        let textLines = doc.splitTextToSize(longText, 9); // Ancho máximo de 9 unidades
        
        // Dibujar cada línea centrada
        textLines.forEach((line:string, index: number) => {
            let textWidth = doc.getTextWidth(line);
            doc.text(line, centerX - textWidth / 2, startY + index * lineHeight);
        });
        

        //Product ID
        let textWidth = doc.getTextWidth(item.productId);
        doc.text(item.productId, centerX - textWidth / 2, startY + textLines.length);

        //RP + Line Red
        doc.setFontSize(37.5);
        const URP = item.productPrice + (item.productPrice / 100 * 20)
        let formattedPrice = URP.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        textWidth = doc.getTextWidth(`$${formattedPrice}`)
        const CenterLine = textWidth + 1
        doc.addImage(RedLine, "PNG", centerX - CenterLine / 2, startY + 3.5* lineHeight, CenterLine , .62)
        doc.text(`$${formattedPrice}`, centerX - textWidth / 2, startY + 4 * lineHeight)
        
        //USD + IVA
        doc.setFontSize(11)
        textWidth = doc.getTextWidth("(USD)(+IVA)")
        doc.text("(USD)(+IVA)", centerX - textWidth / 2, startY + 4.5 * lineHeight)
        
        //Reduced Price text
        doc.setFontSize(18)
        textWidth = doc.getTextWidth("Reduced Price")
        doc.text("Reduced Price",centerX - textWidth / 2, startY + 5.5 * lineHeight)

        //Product Price
        doc.setFontSize(54)
        formattedPrice = item.productPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        doc.text(
          `$${formattedPrice}`,
          centerX - textWidth / 2,
          startY + 7.5 * lineHeight
        );

        //USD + Iva text
        doc.setFontSize(11)
        textWidth = doc.getTextWidth("(USD)(+IVA)")
        doc.text("(USD)(+IVA)",centerX - textWidth / 2, startY + 8 * lineHeight)
      } catch {
       // this.commonService.showErrorMessage("Error al generar Etiquetas (Ashley) Up4");
      }
    }
  };

    for (const item of up4List) {
      const quantityTags = item.quantityTags || 1;
      for (let i = 0; i < quantityTags; i++) {
        const Position = Positions[labelIndex % 4];
        await addLabel(
          item,
          Position.x,
          Position.y,
          Position.imgX,
          Position.imgY
        );
        labelIndex++;
        if (
          labelIndex % 4 === 0 &&
          labelIndex <
            up4List.length * Math.max(...up4List.map((p) => p.quantityTags))
        ) {
          doc.addPage();
        }
      }
    }
   // this.commonService.showSuccessMessage("Etiquetas (Ashley) Up4");
    doc.save("Etiquetas (Ashley) Up4.pdf");
    return 200;
  }

  private async Up16Label(up16List: Array<any>, TagTemplate:string) {
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

    //Cargamos la fuente Spectral.tff
    const SpectralFont = "../../../assets/Font/Spectral-Bold.ttf";
    doc.addFileToVFS("Spectral-Bold.ttf", SpectralFont);
    doc.addFont("Spectral-Bold.ttf", "Spectral", "Bold");

    let ImgURL = "../../../../assets/Labels Img/Ashley_etiquetas.png";
    let background = "../../../../assets/Labels Img/Ashley_Clearanace.png";

    const labelWidth = 5.4;

    const Positions = [ 
      {x: 0.0, y: 0.0, imgX: 0.0, imgY: 0.0},
      {x: 5.4, y: 0.0, imgX: 5.4, imgY: 0.0},
      {x: 10.8, y: 0.0, imgX: 10.8, imgY: 0.0},
      {x: 16.2, y: 0.0, imgX: 16.2, imgY: 0.0},
      {x: 0.0, y: 6.9, imgX: 0.0, imgY: 6.9},
      {x: 5.4, y: 6.9, imgX: 5.4, imgY: 6.9},
      {x: 10.8, y: 6.9, imgX: 10.8, imgY: 6.9},
      {x: 16.2, y: 6.9, imgX: 16.2, imgY: 6.9},
      {x: 0.0, y: 13.8, imgX: 0.0, imgY: 13.8},
      {x: 5.4, y: 13.8, imgX: 5.4, imgY: 13.8},
      {x: 10.8, y: 13.8, imgX: 10.8, imgY: 13.8},
      {x: 16.2, y: 13.8, imgX: 16.2, imgY: 13.8},
      {x: 0.0, y: 20.7, imgX: 0.0, imgY: 20.7},
      {x: 5.4, y: 20.7, imgX: 5.4, imgY: 20.7},
      {x: 10.8, y: 20.7, imgX: 10.8, imgY: 20.7},
      {x: 16.2, y: 20.7, imgX: 16.2, imgY: 20.7}
    ];
    
    let labelIndex = 0;

    const addLabel = async (
      item: any,
      x: number,
      y: number,
      imgX: number,
      imgY: number
    ) => {
      if(item.TagTemplate === "Clearance"){
      try {
        const img = await this.loadImage(background);

        const ImgWidth = 5.4; 
        const ImgHeight = 6.9;

        const imgPaddingX = 0;
        const imgPaddingY = 0;
        const imgStartX = imgX + imgPaddingX;
        const imgStartY = imgY + imgPaddingY;

        const centerX = x + labelWidth / 2;
        const startY = y + 1.35;
        const lineHeight = 0.75;

        //Background
        doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

        //Product Name
        doc.setFont("Helvetica", "normal");
        doc.setFontSize(11)
        let longText = item.productName;
        let textLines = doc.splitTextToSize(longText, 4.5); // Ancho máximo de 4.5 unidades
     
        // Dibujar cada línea centrada
        textLines.forEach((line:string, index: number) => {
            let textWidth = doc.getTextWidth(line);
            doc.text(line, centerX - textWidth / 2, startY + index * lineHeight);
        });

        //Product ID
        let textWidth = doc.getTextWidth(item.productId);
        doc.text(item.productId, centerX - textWidth / 2, startY + lineHeight * textLines.length - .25);
    
        //Retail Price
        let Retail = item.productRetail;
        let RetailPrice: Number = +Retail;
        let formattedPrice = RetailPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        doc.text(
          `$${formattedPrice}`,
          centerX - textWidth / 2,
          startY + 3.75 * lineHeight
        );

        //Product Price
        doc.setFontSize(28);
        doc.setTextColor(237, 28, 36);
        formattedPrice = item.productPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        doc.text(
          `$${formattedPrice}`,
          centerX - textWidth / 2,
          startY + 5.35 * lineHeight
        );

        doc.setTextColor(0, 0, 0);
        doc.setFontSize(13);
        let price = +item.productPrice;
        let saving = Retail - price;

        formattedPrice = saving.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });
        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        doc.text(
          `$${formattedPrice}`,
          centerX - textWidth / 2,
          startY + 6.55 * lineHeight
        );
      } catch (err) {
        // this.commonService.showErrorMessage("Error al generar Etiquetas (Ashley) Up16");
      }
      }else if(item.TagTemplate === "Outlet"){
      try {
        const img = await this.loadImage(ImgURL);

        const ImgWidth = 2.4;
        const ImgHeight = 1.7;

        const imgPaddingX = 1.5;
        const imgPaddingY = 0.1;
        const imgStartX = imgX + imgPaddingX;
        const imgStartY = imgY + imgPaddingY;

        const centerX = x + labelWidth / 2;
        const startY = y + 2.5;
        const lineHeight = 0.75;

        doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

        doc.setFontSize(12);
        doc.setFont("Helvetica", "normal");
        let longText = item.productName;
        let textLines = doc.splitTextToSize(longText, 4.5); // Ancho máximo de 9 unidades
        
        // Dibujar cada línea centrada
        textLines.forEach((line:string, index: number) => {
            let textWidth = doc.getTextWidth(line);
            doc.text(line, centerX - textWidth / 2, startY + index * lineHeight);
        });
        
        let textWidth = doc.getTextWidth(item.productId);
        doc.text(
          item.productId,
          centerX - textWidth / 2, startY + lineHeight * textLines.length -.25
        );
        doc.setFontSize(28);
        doc.setFont("Spectral", "bold");
        const formattedPrice = item.productPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });

        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        doc.text(
          `$${formattedPrice}`,
          centerX - textWidth / 2,
          startY + 1.8 + lineHeight
        );

        doc.setFont("Helvetica", "normal");
        doc.setFontSize(10);
        textWidth = doc.getTextWidth("USD");
        doc.text("USD", centerX - textWidth / 2, startY + 2.3 + lineHeight);

        doc.setFontSize(12);
        textWidth = doc.getTextWidth("(+IVA)");
        doc.text("(+IVA)", centerX - textWidth / 2, startY + 2.8 + lineHeight);

      } catch (err) {
       // this.commonService.showErrorMessage("Error al generar Etiquetas (Ashley) Up16");
      }
    }
  };

    for (const item of up16List) {
      const quantityTags = item.quantityTags || 1;
      for (let i = 0; i < quantityTags; i++) {
        const Position = Positions[labelIndex % 16]; 
        await addLabel(
          item,
          Position.x,
          Position.y,
          Position.imgX,
          Position.imgY
        );
        labelIndex++;
        if (
          labelIndex % 16 === 0 && 
          labelIndex <
          up16List.length * Math.max(...up16List.map((p) => p.quantityTags))) {
          doc.addPage(); 
        }
      }
    }
    //this.commonService.showSuccessMessage("Etiquetas (Ashley) Up16 Generadas");
    doc.save("Etiquetas (Ashley) Up16.pdf");
    return 200;
  }

  private async Up80Label(up80List: Array<any>) {
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

    // Carga y define la fuente Spectral-Bold
    const SpectralFont = "../../../assets/Font/Spectral-Bold.ttf";
    doc.addFileToVFS("Spectral-Bold.ttf", SpectralFont);
    doc.addFont("Spectral-Bold.ttf", "Spectral", "Bold");

    const ImgURL = "../../../../assets/Labels Img/Ashley_etiquetas.png"

    // Definición de las posiciones de las etiquetas
    const labelWidth = 1.268181818181818;
    const labelHeight = 4.4;
    const margin = 0.75;
    const Positions = [];

    const totalRows = 20; // Número total de filas (ajusta según sea necesario)
    const labelsPerRow = 4; // Cantidad de etiquetas por fila

    for (let row = 1; row <= totalRows; row++) {
      for (let col = 0; col < labelsPerRow; col++) {
        const x = col * labelHeight + margin * (col + 1);
        const y = labelWidth * row;
        Positions.push({
          x: x,
          y: row < 21 ? y : 1.268181818181818,
          imgX: x,
          imgY: row < 21 ? y : 1.268181818181818,
        });
      }
    }

    let labelIndex = 0;

    const addLabel = async (
      item: any,
      x: number,
      y: number,
      imgX: number,
      imgY: number
    ) => {
      try {
        const img = await this.loadImage(ImgURL);
        const ImgWidth = 1.3;
        const ImgHeight = 0.87;

        const imgPaddingX = 1.5;
        const imgPaddingY = 0;
        const imgStartX = imgX + imgPaddingX;
        const imgStartY = imgY + imgPaddingY;

        const centerX = x + labelWidth * 1.7;
        const startY = y + 0.85;
        const lineHeight = 0.3;

        doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

        // Añadir nombre del producto y ID
        doc.setFontSize(5);
        doc.setFont("Helvetica", "bold");
        const productText = `${item.productName} ${item.productId}`;
        let textWidth = doc.getTextWidth(productText);
        doc.text(productText, centerX - textWidth / 2, startY);

        // Añadir precio con textos en diferentes tamaños
        const priceText = `$${item.productPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        })}`;
        const currencyText = "(USD)";
        const taxText = "(+IVA)";

        // Configura el tamaño de la fuente para el precio principal
        doc.setFontSize(8);
        doc.setFont("Spectral", "bold");
        let priceWidth = doc.getTextWidth(priceText + currencyText + taxText);
        doc.text(priceText, centerX - priceWidth / 2, startY + 0.3);

        // Ajusta el tamaño de la fuente para "(USD)" y "(+IVA)"
        doc.setFontSize(6);
        doc.setFont("Helvetica", "normal");
        doc.text(currencyText + taxText, centerX, startY + lineHeight);
      } catch (err) {
       // this.commonService.showErrorMessage(
       //   "Error al generar las Etiquetas (Ashley) Up80"
       // );
      }
    };

    for (const item of up80List) {
      const quantityTags = item.quantityTags || 1; // Usa 1 si quantityTags no está definido
      for (let i = 0; i < quantityTags; i++) {
        const Position = Positions[labelIndex % Positions.length];
        await addLabel(
          item,
          Position.x,
          Position.y,
          Position.imgX,
          Position.imgY
        );
        labelIndex++;
        if (
          labelIndex % Positions.length === 0 &&
          labelIndex <
            up80List.length * Math.max(...up80List.map((p) => p.quantityTags))
        ) {
          doc.addPage();
        }
      }
    }
   // this.commonService.showSuccessMessage("Etiquetas (Ashley) Up80 Generadas");
    doc.save("Etiquetas (Ashley) Up80.pdf");
    return 200;
  }

  private async Up2Label(up2List: Array<any>) {
    const doc = new jsPDF({
      orientation: "l",
      unit: "cm",
      format: "letter",
    });

    const Logo = "../../../../assets/Labels Img/Ashley_etiquetas.png";

    const labelWidth = 13.95;

    const Positions = [
      { x: 0, y: 0, imgX: 0, imgY: 0 },
      { x: labelWidth, y: 0, imgX: labelWidth, imgY: 0 },
    ];

    let labelIndex = 0;

    const loadImage = (url: string) => {
      return new Promise<HTMLImageElement>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(img);
        img.onerror = (err) => reject(err);
      });
    }

    const addLabel = async (
      Item: any,
      x: number, 
      y: number, 
      imgX: number,
      imgY: number
    ) => {
      try{
        const logo = await loadImage(Logo);
        const productImg = Item.productImage;
        const product = await loadImage(productImg);
        
        const logoWidth = 8;
        const logoHeight = 5.75;
        
        const imgStartY = imgY;

        const centerX = x + labelWidth / 2;
        const startY = y + 4.75;
        const lineHeight = 1;

        //Logo
        doc.addImage(logo, "PNG", centerX - 3.9, imgStartY, logoWidth, logoHeight);

        //Product Name
        doc.setFont("Helvetica", "bold");
        doc.setFontSize(31)
        let filteredProductName = this.keywordsService.filterProductName(Item.productName, keywordsConfig.up2Keywords);
        let longText = filteredProductName;
        let textLines = doc.splitTextToSize(longText, 4); // Ancho máximo de 9 unidades

        // Dibujar cada línea centrada
        textLines.forEach((line:string, index: number) => {
            let textWidth = doc.getTextWidth(line);
            doc.text(line, centerX - textWidth / 2, startY + index * lineHeight + 1.3 );
        });

        //Product ID
        let textWidth = doc.getTextWidth(Item.productId);
        doc.text(Item.productId, centerX - textWidth / 2, startY + textLines.length + 1.6);

        //Medidas
        filteredProductName = this.keywordsService.filterProductName(Item.productName, keywordsConfig.up2MessurementsKeywords);
        textWidth = doc.getTextWidth(filteredProductName);
        doc.text(filteredProductName, centerX - textWidth / 2, startY + textLines.length + 2.9);

        //Product Img
        doc.addImage(product, "PNG", centerX - 3.5, startY * lineHeight + 4, 7, 9.5);

        //Product Price
        doc.setFontSize(52);
        const formattedPrice = Item.productPrice.toLocaleString("en-US", {
          minimumFractionDigits: 2,
        });

        textWidth = doc.getTextWidth(`$${formattedPrice}`);
        doc.text(`$${formattedPrice}`, centerX - textWidth / 2, startY + lineHeight * 15);
     
        //USD + IVA
        doc.setFontSize(22)
        textWidth = doc.getTextWidth("(USD) (+IVA)")
        doc.text("(USD) (+IVA)", centerX - textWidth / 2, startY + lineHeight * 16);

      }catch (err) {
     // this.commonService.showErrorMessage("Error al generar las Etiquetas (Ashley) Up2");
     }
    };

    for (const item of up2List) {
      const quantityTags = item.quantityTags || 1;
      for (let i = 0; i < quantityTags; i++) {
        const Position = Positions[labelIndex % 2];
        await addLabel(
          item,
          Position.x,
          Position.y,
          Position.imgX,
          Position.imgY
        );
        labelIndex++;
        if (
        labelIndex % 2 === 0 &&
        labelIndex <
          up2List.length * Math.max(...up2List.map((p) => p.quantityTags))){
          doc.addPage();
        }
      }
    }
    //this.commonService.showSuccessMessage("Etiquetas (Ashley) Up2 Generadas");
    doc.save("Etiquetas (Ashley) Up2.pdf")
    return 200;
  }

}