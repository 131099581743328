import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PricesComponent } from './prices.component';
import { AuthGuard } from 'src/app/auth/auth-guard.service';
import { UpdatePricesComponent } from './update-prices/update-prices.component';
import { UpdatePriceListComponent } from './update-price-list/update-price-list.component';
import { UsersService } from '../users/users.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { UpdatePricesEditComponent } from './update-prices/update-prices-edit/update-prices-edit.component';

export const pricesRoutes : Routes =[
  {path: 'precios', canActivate:[AuthGuard], component: PricesComponent, children:[
    {path: 'Actualizar-Individual', canActivate:[AuthGuard], component: UpdatePricesComponent},
    {path: 'Actualizar-Masiva', canActivate:[AuthGuard], component: UpdatePriceListComponent},
  ]}
];

@NgModule({
  declarations: [
    UpdatePricesComponent,
    UpdatePriceListComponent,
    UpdatePricesEditComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [],
  providers: [UsersService],
})
export class PricesModule { }
