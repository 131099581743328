import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-sales-reports-view-mail',
  templateUrl: './sales-reports-view-mail.component.html',
  styleUrls: ['./sales-reports-view-mail.component.css']
})
export class SalesReportsViewMailComponent implements OnInit {

  text: string = ''

  constructor(
    public config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.text = this.config.data.contenido
  }

}
