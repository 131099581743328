export enum field_types {
  Lote = 'Lote',
  Manzana = 'Manzana',
  Seccion = 'Seccion',
  Calle = 'Calle',
  Numero = 'Numero',
  Casa = 'Casa',
  Cerrada = 'Cerrada',
  Privada = 'Privada',
  Villa = 'Villa',
  Letra = 'Letra'
}
