import { Component, OnInit, ViewChild } from '@angular/core';
import { Cliente, Notas } from '../../sales.interfaces';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { faFilePen, faEdit, faAdd } from '@fortawesome/free-solid-svg-icons';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/shared/common.service';
import { SalesClientesNoteEditCreateComponent } from './sales-clientes-note-edit-create/sales-clientes-note-edit-create.component';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';
import { SalesClientesNoteDeleteComponent } from './sales-clientes-note-delete/sales-clientes-note-delete.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { SalesService } from '../../sales.service';
import { SalesClientesViewFollowingComponent } from './sales-clientes-view-following/sales-clientes-view-following.component';
import { Store } from 'src/app/shared/common.enums';
import { SalesClientesEditComponent } from '../sales-clientes-edit/sales-clientes-edit.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SalesClientesAddressComponent } from '../sales-clientes-address/sales-clientes-address.component';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';

@Component({
  selector: 'app-sales-clientes-view',
  templateUrl: './sales-clientes-view.component.html',
  styleUrls: ['./sales-clientes-view.component.css'],
  providers: [MessageService, DialogService]
})
export class SalesClientesViewComponent implements OnInit {
  
  @ViewChild('alert') alert!: AlertV2Component;

  showAddresButton: boolean = this.config.data.button
  private destroy$ = new Subject();
  seguimiento = ''
  loading = true
  cliente: Cliente = {
    id: 0,
    nombre: '',
    apellido: '',
    phone: '',
    phone_2: '',
    email: '',
    email_2: '',
    create_at: '',
    id_type_interest: 0,
    asist_id: 0,
    id_type_sell: 0,
    id_reference: 0,
    store: Store.ashley,
    store_visit: Store.ashley,
    id_following: 0,
    rental: '',
    contactMethod: '',
    id_shared_asist: 0,
    tipo_de_interes: {},
    asistente: {},
    asistente_compartido: {},
    referencia: {},
    seguimiento: {},
    //address: [],
    type_client: {},
    idioma: ''
  };
  
  notas: Notas[] = [];
  historial: any[]=[]

  formatearFecha(fecha: string): string {
    return moment(fecha).locale('es').format('D [de] MMMM [del] YYYY [a las] HH:mm');
  }

  IconAdd = faAdd
  IconEdit = faEdit
  faNotePen = faFilePen

  constructor(
    public config: DynamicDialogConfig,
    public commonService: CommonService,
    public dialogService: DialogService,
    public alertService: AlertService,
    public dialog: MatDialog,
    public saleSevice: SalesService,
    public ref: DynamicDialogRef,
  ) {
   }

   ngOnInit(): void {
    this.cargarCliente();
    this.cargarHistorialByCliente();
    this.getNotasByCliente();
    this.loading = false
    this.seguimiento = this.config.data.seguimiento
  }

  showAddress(id: number, nombre: string) {
   this.dialogService.open(SalesClientesAddressComponent, {
      header: `Dirección(es) de ${nombre}`,
      width: '70%',
      data: {id, nombre}
    })
  }

  eliminarCliente(id:number){
    const data = {idCliente: id}
    
    const body = `<h2>¿Seguro que quiere eliminar al cliente?</h2>`
    const confirmationData: ConfirmationAlert = {
        title: 'Eliminar dirección del cliente',
        body: body,
        cancelLabel: "Cerrar",
        confirmLabel: 'Eliminar',
        confirmDanger: true,
      onConfirm: () => {
         this.deleteCliente(data)
      }
    };
    this.alertService.setConfirmationAlertv2(this.alert, confirmationData);
  }

  deleteCliente(data: any){
    this.saleSevice.deleteCliente(data).subscribe(
      res=>{
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showSuccessMessage(res.msg)
        this.ref.close();
      },err=>{
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showErrorMessage(err)
      }
    )
  }

  onEditeClient(cliente: Cliente) {
    const ref = this.dialogService.open(SalesClientesEditComponent, {
      header: `Editar Cliente`,
      width: '70%',
      data : {cliente, action: 'edit'}
    });
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((cliente) => {
      this.cargarCliente();
    });
  }

  onAddFollowin(idCliente: number) {
    const ref = this.dialogService.open(SalesClientesViewFollowingComponent, {
      header: `Agregar Seguimiento`,
      width: '50%',
      data : {idCliente,
              asistente: this.cliente.asistente.Clave,
              idAsistente: this.cliente.asistente.idAsistans
            }
    });
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((cliente) => {
      this.cargarCliente();
    });
  }

  onViewNote(index: number) {
    let mensaje = ""
    let folio = 0
    mensaje = this.notas[index].Nota
    folio = this.notas[index].folio
    const ref = this.dialogService.open(SalesClientesNoteEditCreateComponent, {
      header: 'Ver Nota',
      width: '50%',
      data: {
            note: mensaje,
            folio: folio,
            idNote: this.notas[index].id,
            status: 'view'}
    });
    ref.onClose.subscribe((note) => {
      if (note) {
        this.getNotasByCliente();
       }
    });
  }
  
  onNoteCreate() {
    const newNote = {mensaje: ''}
    const ref = this.dialogService.open(SalesClientesNoteEditCreateComponent, {
      header: 'Crear Nota',
      width: '50%',
      data: { note: newNote,
              idCliente: this.config.data.id,
              status: 'create'
            }
    });
    ref.onClose.subscribe((data) => {
      if (data) {
       this.getNotasByCliente();
      }
    });
  }


/*   onNoteDelete(i: number): void {
    const dialogRef = this.dialog.open(SalesClientesNoteDeleteComponent)
    dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.saleSevice.deleteNota(this.notas[i].id).subscribe(
            (response) => {
              this.commonService.showSuccessMessage('Nota eliminada');
              this.getNotasByCliente();
            },
            (error) => {
              console.error('Error al eliminar la nota', error);
            });
        }else if (result === false) {
          this.commonService.showInfoMessage('Operación cancelada');
        }
    });
  } */

  getNotasByCliente(){
    this.saleSevice.getNotasByCliente(this.config.data.id).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.notas = response.msg;
      },
      (error) => {
        this.commonService.showErrorMessage(error);
      }
    )
  }

  cargarCliente() {
    this.saleSevice.getClienteById(this.config.data.id).pipe(takeUntil(this.destroy$)).subscribe(
      (cliente) => {
        this.cliente = cliente;
      },
      (error) => {
        this.commonService.showErrorMessage(error);
      }
    )
  }

  cargarHistorialByCliente() {
    this.saleSevice.getHistorialByCliente(this.config.data.id).pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.historial = response.msg;
      },(error) => {
        this.commonService.showErrorMessage(error);
      }
    );
  }
}
