import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PricesService {

  constructor(
    private http: HttpClient
  ) { }

    getPrices(id: number, store: string): Observable<any> {
      return this.http.get<any>(`${environment.endpoint}prices/getPrices/${id}&${store}`);
    }

    getItems(provider: string): Observable<Blob> {
      return this.http.get(`${environment.endpoint}prices/getItems/${provider}`, {
        responseType: 'blob' 
      });
    }    

    updatePrices(data: any, store: string): Observable<any> {
      return this.http.post<any>(`${environment.endpoint}prices/updatePrices`, data);
    }
  }
  
