import { CommonService } from 'src/app/shared/common.service';
import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SalesService } from '../../../sales.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Template } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-sales-config-mail',
  templateUrl: './sales-config-mail-template.component.html',
  styleUrls: ['./sales-config-mail-template.component.css']
})

export class SalesConfigMailTemplateComponent implements OnInit {

  public Subject: string = this.config.data.values // Contenido del editor
  public text: string = this.config.data.values; // Contenido del editor
  private destroy$ = new Subject<void>();

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public salesServices: SalesService,
    public dialogService: DialogService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    if(this.config.data.idioma === 'Español'){
      this.text = this.config.data.values.Template_ESP
      this.Subject = this.config.data.values.Subject_ESP
      console.log("Español: ", this.text)
    }else if(this.config.data.idioma === 'Ingles'){
      this.text = this.config.data.values.Template_ING
      this.Subject = this.config.data.values.Subject_ING
      console.log("ingles: ", this.text)
    }
  }

  // Método para insertar variables (por ejemplo, para el nombre del cliente)
  insertVariable(variable: string) {
    this.text += `${variable}`;
  }

  saveTemplate() {

    if(this.text === ""){
      this.commonService.showWarningMessage("El template no puede estar vacio")
      return
    }else if(this.Subject === ""){
      this.commonService.showWarningMessage("El asunto no puede estar vacio")
      return 
    }

    let data = {
      Template: this.text,
      Subject: this.Subject,
      idPaso: this.config.data.values.id,
      idioma: this.config.data.idioma
    }

    this.salesServices.saveTemplate(data).pipe(takeUntil(this.destroy$)).subscribe(
      success => {
        this.commonService.showSuccessMessage(success.msg)
        this.ref.close()
      },
      error => {
        this.commonService.showErrorMessage(error)
      }
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
