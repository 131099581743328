import { CommonService } from './../../../../shared/common.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SalesService } from '../../sales.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface Seguimiento {
  accion: string;
}

@Component({
  selector: 'app-sales-clientes-util',
  templateUrl: './sales-clientes-util.component.html',
  styleUrls: ['./sales-clientes-util.component.css']
})
export class SalesClientesUtilComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  text = ''
  
  Subject = ''

  loading = true

  form: FormGroup
  seguimientos: Seguimiento[] = [];  // Definición de seguimientos para el *ngFor

  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public salesService: SalesService,
    public commonService: CommonService
  ) {
    console.log(this.config.data)
    // Inicializar FormGroup y agregar el control following
    this.form = this.fb.group({
        accion: [''],
        text: [''],
        email: [''],
        Subject: [''],
        file: [null]
    });
  }

  ngOnInit(): void {
    this.getMailToSend();
    this.getSeguimientos();  // Llamar para cargar los seguimientos al iniciar el componente
  }

  insertVariable(variable: string){
    console.log(variable)
  }

  getSeguimientos(): void {
    this.salesService.getAccion().pipe(takeUntil(this.destroy$)).subscribe(
      (acciones) => {
        console.log('Acciones:', acciones);
        // Filtrar seguimientos activos y asignarlos a la variable `seguimientos`
        this.seguimientos = acciones.filter((accion: any) => accion.status === 'Activo');
        console.log('Seguimientos:', this.seguimientos);
      },
      (error) => {
        console.error('Error al obtener seguimientos:', error);
      }
    );
  }

  getMailToSend(): void {
    const { idFollowing, step, idioma, idCliente } = this.config.data;
    this.salesService.getMailsToSend(idFollowing, step, idioma, idCliente).pipe(takeUntil(this.destroy$)).subscribe(
      (Mail) => {
        this.text = Mail.mail || 'No se encontró el contenido del mensaje';
        this.Subject = Mail.subject || 'No se encotro asunto'
        this.form.controls['text'].setValue(this.text);
        this.form.controls['email'].setValue(Mail.cliente.email)
        this.form.controls['Subject'].setValue(this.Subject)
        this.loading = false
        console.log(this.text)
      },
      (error) => {
        this.commonService.showErrorMessage(error);
      }
    );
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      this.form.patchValue({ file: file });
    }
  }
  
  
  onSubmit(): void {
    this.loading = true;
  
    const { accion, text, email, Subject, file } = this.form.value;
  
    const data = new FormData();
    data.append('accion', JSON.stringify(accion)); 
    data.append('text', text); 
    data.append('email', email);
    data.append('Subject', Subject);
  
    if (file) {
      data.append('file', file, file.name);
    }

    data.append('ClienteID', this.config.data.idCliente);

    const request$ = file 
      ? this.salesService.SendFollowingMailWithFile(data)
      : this.salesService.SendFollowingMail(data);
  
    request$.pipe(takeUntil(this.destroy$)).subscribe(
      (response) => {
        this.commonService.showSuccessMessage(response.msg);
        this.loading = false;
        this.ref.close(response.msgId); 
      },
      (err) => {
        this.commonService.showErrorMessage(err);
        this.loading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
