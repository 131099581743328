import { Component, OnInit } from '@angular/core';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/shared/common.service';

@Component({
  selector: 'app-sales-config-address',
  templateUrl: './sales-config-address.component.html',
  styleUrls: ['./sales-config-address.component.css']
})
export class SalesConfigAddressComponent implements OnInit {

  tipo = "todos"
  faGears = faGears

  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
  }

  onSizeChange(){
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }
}
