import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class TagOutletService {

  constructor() {}

  public async generateLabelsPDF(
    up4List: Array<any>,
    up16List: Array<any>,
    up80List: Array<any>,
    TagTemplate: string,
  ): Promise<number> {

    let up2Status = 200;
    let up4Status = 200; 
    let up16Status = 200;
    let up80Status = 200;

    if (up4List && up4List.length > 0) {
      up4Status = await this.Up4Label(up4List, TagTemplate);
    }

    if (up16List && up16List.length > 0) {
      up16Status = await this.Up16Label(up16List, TagTemplate);
    }

    if (up80List && up80List.length > 0) {
      up80Status = await this.Up80Label(up80List);
    } 

    if (up2Status === 200 && up4Status === 200 && up16Status === 200 && up80Status === 200) {
      return 200;
    } else {
      return 500;
    }
  }

  private async Up4Label(up4List: Array<any>, TagTemplate: string){
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

      const labelWidth = 10.85;
      const labelHeight = 13.95;

      const Positions = [
        { x: 0, y: 0, imgX: 0, imgY: 0 },
        { x: labelWidth, y: 0, imgX: labelWidth, imgY: 0 },
        { x: 0, y: labelHeight, imgX: 0, imgY: labelHeight },
        { x: labelWidth, y: labelHeight, imgX: labelWidth, imgY: labelHeight },
      ];

      let labelIndex = 0;

      const loadImage = (url: string) => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = () => resolve(img);
          img.onerror = (err) => reject(err);
        });
      };

      const addLabel = async (
        item: any,
        x: number,
        y: number,
        imgX: number,
        imgY: number,
        background: string,
        PPfontSize: number,
        PPPosition: number,
        RPfontSize: number,
        RPPosition: number,
        SVfontSice: number,
        SVPosition: number,
        SVPositionY: number,
        SVColor: { r: number; g: number; b: number }
      ) => {
        try {
          const img = await loadImage(background);

          const ImgWidth = 10.85;
          const ImgHeight = 13.95;

          const imgStartX = imgX;
          const imgStartY = imgY;

          const centerX = x + labelWidth / 2;
          const startY = y + 2.3;
          const lineHeight = 1;

          doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

          doc.setFontSize(20);
          doc.setTextColor(0, 0, 0);
          doc.setFont("Helvetica", "normal");
          let longText = item.productName;
          let textLines = doc.splitTextToSize(longText, 9); // Ancho máximo de 9 unidades
          
          // Dibujar cada línea centrada
          textLines.forEach((line:string, index: number) => {
              let textWidth = doc.getTextWidth(line);
              doc.text(line, centerX - textWidth / 2, startY + index * lineHeight);
          });
          
          let textWidth = doc.getTextWidth(item.productId);
          doc.text(
            item.productId,
            centerX - textWidth / 2,
            startY + lineHeight * textLines.length // Añadir después de las líneas de productName
          );

          doc.setFontSize(PPfontSize);
          doc.setFont("Helvetica", "bold");
          let formattedPrice = item.productPrice.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          });
          textWidth = doc.getTextWidth(`$${formattedPrice}`);
          doc.text(
            `$${formattedPrice}`,
            centerX - textWidth / 2,
            startY + (PPPosition) * lineHeight
          );
          
          doc.setFontSize(RPfontSize);
          doc.setFont("Helvetica", "normal");
          let Retail = item.productRetail;
          let RetailPrice: Number = +Retail;
          formattedPrice = RetailPrice.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          });
          textWidth = doc.getTextWidth(`$${formattedPrice}`);
          doc.text(
            `$${formattedPrice}`,
            centerX - textWidth / 2,
            startY + (RPPosition) * lineHeight
          );
          
          let price = +item.productPrice;
          let saving = Retail - price;
          
          formattedPrice = saving.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          });
          textWidth = doc.getTextWidth(`$${formattedPrice}`);
          doc.setTextColor(SVColor.r, SVColor.g, SVColor.b);
          doc.setFontSize(SVfontSice);
          doc.text(
            `$${formattedPrice}`,
            centerX - textWidth / 2 + SVPositionY,
            startY + (SVPosition) * lineHeight
          );
          
        } catch (err) {
          //this.commonService.showErrorMessage("Error al generar etiquetas Up4");
        }
      };

      for (const item of up4List) {
        let background = "";
        let PPfontSize = 0; // Product Price
        let PPPosition = 0;
        let RPfontSize = 0; // Retail Price
        let RPPosition = 0;
        let SVfontSice = 0; // Saving
        let SVPosition = 0;
        let SVPositionY = 0;
        let SVColor = { r: 0, g: 0, b: 0 };

        switch (item.TagTemplate) {
          case "Clearance":
            background = "../../../../assets/Labels Img/Clearance.png";
            PPfontSize = 36;
            PPPosition = 7.5;
            RPfontSize = 20;
            RPPosition = 5.45;
            SVfontSice = 16;
            SVPosition = 8.75;
            SVPositionY = 0;
            SVColor = { r: 0, g: 0, b: 0 };
            break;
          case "Outlet":
            background = "../../../../assets/Labels Img/Display.png";
            PPfontSize = 64;
            PPPosition = 6.25;
            RPfontSize = 28;
            RPPosition = 3.15;
            SVfontSice = 26;
            SVPosition = 9.85;
            SVPositionY = 0;
            SVColor = { r: 246, g: 87, b: 19 };
            break;
          default:
            break;
        }

        const quantityTags = item.quantityTags || 0;
        for (let i = 0; i < quantityTags; i++) {
          const Position = Positions[labelIndex % 4];
          await addLabel(
            item,
            Position.x,
            Position.y,
            Position.imgX,
            Position.imgY,
            background,
            PPfontSize,
            PPPosition,
            RPfontSize,
            RPPosition,
            SVfontSice,
            SVPosition,
            SVPositionY,
            SVColor
          );
          labelIndex++;
          if (
            labelIndex % 4 === 0 &&
            labelIndex <
              up4List.length * Math.max(...up4List.map((p) => p.quantityTags))
          ) {
            doc.addPage();
          }
        }
      }
     // this.commonService.showSuccessMessage("Etiquetas Up4 generadas");
      doc.save("Outlate Up4.pdf");
      return 200;
  }

  private async Up16Label(up16List: Array<any>, TagTemplate: string){
    const doc = new jsPDF({
      orientation: "p",
      unit: "cm",
      format: "letter",
    });

      const labelWidth = 5.425;
      const labelHeight = 6.975;

      const Positions = [];

      for (let row = 0; row < 4; row++) {
        for (let col = 0; col < 4; col++) {
          Positions.push({
            x: col * labelWidth,
            y: row * labelHeight,
            imgX: col * labelWidth,
            imgY: row * labelHeight,
          });
        }
      }

      let labelIndex = 0;

      const loadImage = (url: string) => {
        return new Promise<HTMLImageElement>((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = () => resolve(img);
          img.onerror = (err) => reject(err);
        });
      };

      const addLabel = async (
        item: any,
        x: number,
        y: number,
        imgX: number,
        imgY: number,
        background: string,
        PPfontSize: number,
        PPPosition: number,
        RPfontSize: number,
        RPPosition: number,
        SVfontSice: number,
        SVPosition: number,
        SVPositionY: number,
        SVColor: { r: number; g: number; b: number }
      ) => {
        try {
          const img = await loadImage(background);

          const ImgWidth = 5.425;
          const ImgHeight = 6.975;

          const imgStartX = imgX;
          const imgStartY = imgY;

          const centerX = x + labelWidth / 2;
          const startY = y + 1.3;
          const lineHeight = 0.5;

          doc.addImage(img, "PNG", imgStartX, imgStartY, ImgWidth, ImgHeight);

          doc.setFontSize(10);
          doc.setTextColor(0, 0, 0);
          doc.setFont("Helvetica", "normal");
          let longText = item.productName;
          let textLines = doc.splitTextToSize(longText, 5); // Ancho máximo de 9 unidades
          
          // Dibujar cada línea centrada
          textLines.forEach((line:string, index: number) => {
              let textWidth = doc.getTextWidth(line);
              doc.text(line, centerX - textWidth / 2, startY + index * lineHeight);
          });
          
          let textWidth = doc.getTextWidth(item.productId);
          doc.text(
            item.productId,
            centerX - textWidth / 2,
            startY + lineHeight * textLines.length // Añadir después de las líneas de productName
          );

          doc.setFontSize(PPfontSize);
          doc.setFont("Helvetica", "bold");
          let formattedPrice = item.productPrice.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          });
          textWidth = doc.getTextWidth(`$${formattedPrice}`);
          doc.text(
            `$${formattedPrice}`,
            centerX - textWidth / 2,
            startY + PPPosition * lineHeight
          );

          doc.setFontSize(RPfontSize);
          doc.setFont("Helvetica", "normal");
          let Retail = item.productRetail;
          let RetailPrice: Number = +Retail;
          formattedPrice = RetailPrice.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          });
          textWidth = doc.getTextWidth(`$${formattedPrice}`);
          doc.text(
            `$${formattedPrice}`,
            centerX - textWidth / 2,
            startY + RPPosition * lineHeight
          );

          let price = +item.productPrice;
          let saving = Retail - price;

          formattedPrice = saving.toLocaleString("en-US", {
            minimumFractionDigits: 2,
          });
          textWidth = doc.getTextWidth(`$${formattedPrice}`);
          doc.setTextColor(SVColor.r, SVColor.g, SVColor.b);
          doc.setFontSize(SVfontSice);
          doc.setFont("Helvetica", "Bold")
          doc.text(
            `$${formattedPrice}`,
            centerX - textWidth / 2 + SVPositionY,
            startY + SVPosition * lineHeight
          );
        } catch (err) {
         /*  this.commonService.showErrorMessage(
            "Error al generar etiquetas Up16"
          ); */
        }
      };

      for (const item of up16List) {
        let background = "";
        let PPfontSize = 0; // Product Price
        let PPPosition = 0;
        let RPfontSize = 0; // Retail Price
        let RPPosition = 0;
        let SVfontSice = 0; // Saving
        let SVPosition = 0;
        let SVPositionY = 0;
        let SVColor = { r: 0, g: 0, b: 0 };

        switch (item.TagTemplate) {
          case "Clearance":
            background = "../../../../assets/Labels Img/Clearance.png";
            PPfontSize = 18;
            PPPosition = 7.1;
            RPfontSize = 11;
            RPPosition = 5.2;
            SVfontSice = 10;
            SVPosition = 8.5;
            SVPositionY = 0;
            SVColor = { r: 0, g: 0, b: 0 };
            break;
          case "Outlet":
            background = "../../../../assets/Labels Img/Display.png";
            PPfontSize = 28;
            PPPosition = 5.6;
            RPfontSize = 12;
            RPPosition = 2.85;
            SVfontSice = 12;
            SVPosition = 9.5;
            SVPositionY = 0;
            SVColor = { r: 246, g: 87, b: 19 };
            break;
          default:
            break;
        }

        const quantityTags = item.quantityTags || 0;
        for (let i = 0; i < quantityTags; i++) {
          const Position = Positions[labelIndex % 16];
          await addLabel(
            item,
            Position.x,
            Position.y,
            Position.imgX,
            Position.imgY,
            background,
            PPfontSize,
            PPPosition,
            RPfontSize,
            RPPosition,
            SVfontSice,
            SVPosition,
            SVPositionY,
            SVColor
          );
          labelIndex++;
          if (
            labelIndex % 16 === 0 &&
            labelIndex <
              up16List.length * Math.max(...up16List.map((p) => p.quantityTags))
          ) {
            doc.addPage();
          }
        }
      }
      /* this.commonService.showSuccessMessage(
        "Etiquetas (Outlet) Up16 generadas"
      ); */
      doc.save("Outlate Up16.pdf");
      return 200;
  }
  private async Up80Label(up80List: Array<any>): Promise<number> {
    // no hay diseño para Up80
    return 200; // 
  }
}
