import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/common.service';
import { SalesAddressService } from '../sales-address.service';
import { Address, AddressCasaDesarrollo, Addressdesarrollo, AddressForanea, AddressLocal, AddressPersonalizada, AddressResidencial, addressText, AddressVilla, TipeAddressText, aggregate, TipeClienteText } from './address.enum';
import { FormBuilder } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SalesService } from '../sales.service';
import { SalesClientesAddressViewClientesComponent } from '../sales-clientes/sales-clientes-address/sales-clientes-address-view-clientes/sales-clientes-address-view-clientes.component';
import { DialogService } from 'primeng/dynamicdialog';
import { SalesClientesViewComponent } from '../sales-clientes/sales-clientes-view/sales-clientes-view.component';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();
  return opt.filter(item => item.toLowerCase().includes(filterValue));
}

@Component({
  selector: 'app-sales-search',
  templateUrl: './sales-search.component.html',
  styleUrls: ['./sales-search.component.css']
})
export class SalesSearchComponent implements OnInit {

  faSearch = faSearch

  villa: AddressVilla[] = []
  desarrollo : Addressdesarrollo[] = []
  casa: AddressCasaDesarrollo[] = []
  local : AddressLocal[] = []
  personalizada: AddressPersonalizada[] = []
  foranea: AddressForanea[] = []
  residencial: AddressResidencial[] = []

  SearchTextClientes: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  SearchTextAddrees: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  TipeAddressText: TipeAddressText[] = []
  TipeClientesText: TipeClienteText[] = []
  AddressesTextDesarrollo: addressText[] = []
  AddressesTextCasaDesarrollo: addressText[] = []
  AddressesTextLocal: addressText[] = []
  AddressesTextPersonalizada: addressText[] = []
  AddressesTextResidencial: addressText[] = []
  AddressesTextForanea: addressText[] = []
  AddressesTextVilla: addressText[] = []

  searchSubscription?: Subscription;

  stateForm = this.fb.group({})
  constructor(
    public commonService: CommonService,
    public addressService: SalesAddressService,
    public fb: FormBuilder,
    public salesService: SalesService,
    public dialogService: DialogService
  ) { 
    this.stateForm = this.fb.group({
      text: ['']
    })
  }

  ngOnInit(): void {
    this.getAddresses();
    this.getClientes();
    this.stateForm.get('text')?.valueChanges.pipe(
      startWith(''),
      map(value => this.filterAddresses(value))
    ).subscribe(filteredAddresses => {
      this.SearchTextAddrees.next(filteredAddresses)
    })

    this.stateForm.get('text')?.valueChanges.pipe(
      startWith(''),
      map(value => this.filterClientes(value))
    ).subscribe(filteredClientes => {
      this.SearchTextClientes.next(filteredClientes)
    })
  }

  getAddresses(){
    this.addressService.getAddresses().pipe().subscribe(
      (addresses)=>{
        for(let address of addresses){
          switch(address.tipo){
            case 'desarrollo':
              this.desarrollo.push(address);
              this.buildAddressText(address)
              break
            case 'casa_desarrollo':
              this.casa.push(address)
              this.buildAddressText(address)
              break
            case 'local':
              this.local.push(address)
              this.buildAddressText(address)
              break
            case 'personalizada':
              this.personalizada.push(address)
              this.buildAddressText(address)
              break
            case 'residencial':
              this.residencial.push(address)
              this.buildAddressText(address)
              break
            case 'foranea':
              this.foranea.push(address)
              this.buildAddressText(address)
              break
            case 'villa':
              this.villa.push(address)
              this.buildAddressText(address)
              break
          }
        }
        this.TipeAddressText.push({tipo:'Desarrollos', address: this.AddressesTextDesarrollo})
        this.TipeAddressText.push({tipo:'Casas en desarrollos', address: this.AddressesTextCasaDesarrollo})
        this.TipeAddressText.push({tipo:'Locales', address: this.AddressesTextLocal})
        this.TipeAddressText.push({tipo:'Personalizadas', address: this.AddressesTextPersonalizada})
        this.TipeAddressText.push({tipo:'Residenciales', address: this.AddressesTextResidencial})
        this.TipeAddressText.push({tipo:'Foraneas', address: this.AddressesTextForanea})
        this.TipeAddressText.push({tipo:'Villas', address: this.AddressesTextVilla})
      },(err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  getClientes(){
    this.salesService.getClienteText().pipe().subscribe(
      res =>{
        this.TipeClientesText = res
      },
      err=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  viewAddress(id: number){
    this.dialogService.open(SalesClientesAddressViewClientesComponent, {
      header:"Clientes en la dirección",
      width:"50%",
      data: id
    })
  }

  viewCliente(id: number){
    this.dialogService.open(SalesClientesViewComponent, {
      width: '70%',
      data: {id, button: true} 
    })
  }

  // Función para resaltar coincidencias
  highlightText(text: string, searchValue: string){
    const regex = new RegExp(`(${searchValue})`, 'gi'); // 'g' para global, 'i' para insensible a mayúsculas
    return text.replace(regex, `<strong>$1</strong>`); // Resalta las coincidencias
  };

  private filterAddresses(value: string): any[] {
    const filterValue = value.toLowerCase();
  
    return this.TipeAddressText.map(group => ({
      tipo: group.tipo,
      address: group.address
        .filter(item => item.text.toLowerCase().includes(filterValue)) // Filtrar por coincidencias
        .map(item => ({
          ...item,
          text: this.highlightText(item.text, value), // Resaltar coincidencias en el texto
        })),
    })).filter(group => group.address.length > 0);
  }

  private filterClientes(value: string): any[] {
    const filterValue = value.toLowerCase()
    const filteredClientes = this.TipeClientesText
      .filter(cliente => cliente.nombre.toLowerCase().includes(filterValue)) // Filtrar por coincidencia en el nombre
      .map(cliente => ({
        ...cliente, // Mantener todas las propiedades del cliente
        nombre: this.highlightText(cliente.nombre, value) // Resaltar las coincidencias en el nombre
      }))
      .slice(0, 20)
    return filteredClientes;
  }  

  buildAddressText(address: Address){
    let text = ""
    switch(address.tipo){
    case 'desarrollo':
      text += `Desarrollo: ` + address.desarrollo?.nombre
      text += ` / Edificio: ` + address.edificio?.nombre_edificio
      text += ` / Condominio: ` + address.condominio?.nombre_condominio
      text += ` / Habitaciones: ` + address.condominio?.numero_de_habitaciones
      this.AddressesTextDesarrollo.push({id: address.id, text: text})
    break
    case 'casa_desarrollo':
      text += `Desarrollo: ` + address.desarrollo?.nombre
      text += ` / Casa: ` + address.casa
      this.AddressesTextCasaDesarrollo.push({id: address.id, text: text})
    break
    case 'local':
      text += `Colonia: ` + address.colonia?.nombre
      text += ` / Calle: ` + address.calle?.nombre
      text += ` / Casa: ` + address.casa?.nombre
      text += ` / Entre Calle 1: ` + address.entre_calle_1?.nombre
      text += ` / Entre Calle 2: ` + address.entre_calle_2?.nombre
      this.AddressesTextLocal.push({id: address.id, text: text})
    break
    case 'personalizada':
      text += `Dirección: ` + address.address.direccion
      this.AddressesTextPersonalizada.push({id: address.id, text: text})
    break
    case 'residencial':
      text += `Residencia: ` + address.recidencial
    
      if (address.aggregates && address.aggregates.length > 0) {
        const aggregatesText = address.aggregates.map(aggregate => [`${aggregate.Tipo}`+' : '+aggregate.contenido]).join(' / ')
        text += `/`+ aggregatesText;
      }

      text = text.trim()
      this.AddressesTextResidencial.push({id: address.id, text: text})
    break
    case 'foranea':
      text += `Ciudad: ` + address.ciudad.nombre
      text += ` / Colonia: ` + address.colonia?.nombre
      text += ` / Calle: ` + address.calle?.nombre
      text += ` / Casa: ` + address.casa?.nombre
      text += ` / Entre Calle 1: ` + address.entre_calle_1?.nombre
      text += ` / Entre Calle 2: ` + address.entre_calle_2?.nombre

      this.AddressesTextForanea.push({id: address.id, text: text})
    break
    case 'villa':
      text += `Desarrollo: ` + address.desarrollo?.nombre
      text += ` / Villa: ` + address.villa
      this.AddressesTextVilla.push({id: address.id, text: text})
    break
    }
  }
  
  onSizeChange(){
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }

}
