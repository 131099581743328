import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import {
  faFileCsv,
  faSpinner,
  faTags,
} from "@fortawesome/free-solid-svg-icons";
import { MessageService } from "primeng/api";
import { CommonService } from "src/app/shared/common.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TagsCSVService } from "../tags-csv.service";
import { TagOutletService } from "./tag-outlet.service";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { TagsProcessProdutService } from "../tags-process-product.service";
import { TagOutletProductsComponent } from "./tag-outlet-products/tag-outlet-products.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Component({
  selector: "app-tag-outlet",
  templateUrl: "./tag-outlet.component.html",
  styleUrls: ["./tag-outlet.component.css"],
  providers: [DialogService],
})
export class TagOutletComponent implements OnInit {

  private destroy$ = new Subject();

  ref: DynamicDialogRef | undefined;
  
  // Referencias a las listas en el servicio
  get up4List() {
    return this.productProcess.ProductsUp4;
  }

  get up16List() {
    return this.productProcess.ProductsUp16;
  }

  get up80List() {
    return this.productProcess.ProductsUp80;
  }

  get up2List() {
    return this.productProcess.ProductsUp2;
  }

  // Variables del formulario
  productId: string = "";
  quantityOption: string = "";
  quantityTags: number = 1;

  //Utils
  tagsIcon = faTags;
  loadingIcon = faSpinner;
  CSVicon = faFileCsv;
  loading = false;
  TagTemplate = "";
  pdf_loading = false;

  constructor(
    private tagsService: TagOutletService,
    public commonService: CommonService,
    private messageService: MessageService,
    private csvReader: TagsCSVService,
    private productProcess: TagsProcessProdutService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    public dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.productProcess.getUp2Products()
    this.productProcess.getUp4Products()
    this.productProcess.getUp16Products()
    this.productProcess.getUp80Products()
    }

    ngOnDestroy(): void{
      this.productProcess.clearAllProducts();
     }

     onSizeChange() {
      const option = !this.commonService.fullSize$.value;
      this.commonService.fullSize$.next(option);
    }

  //Manejo del CSV
  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      this.csvReader
        .parseCsv(file)
        .then((data) => {
          data.forEach((item) => {
            this.productProcess.processProduct(item);
          });
          this.cd.detectChanges();
          this.commonService.showSuccessMessage("Productos agregados correctamente");
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  }

  deleteAllProducts() {
    if(!this.productProcess.ProductsUp2.length && !this.productProcess.ProductsUp4.length && !this.productProcess.ProductsUp16.length && !this.productProcess.ProductsUp80.length){
      this.commonService.showWarningMessage("No hay productos para eliminar");
      return;
    }
    this.productProcess.clearAllProducts(); // Limpiar todas las listas de productos
    this.cd.detectChanges();
    this.commonService.showSuccessMessage(`Todos los productos han sido eliminados`);
  }

    // Imprimir Etiquetas
    async OnLabelsPrint(): Promise<void> {
      if (!this.up4List.length && !this.up16List.length && !this.up80List.length) {
        this.commonService.showWarningMessage("No hay etiquetas para imprimir");
        return;
      }

      this.commonService.showSuccessMessage("Generando etiquetas, por favor espere...");

      this.pdf_loading = true;

      try {
        // Espera el resultado de la generación de etiquetas
        const status = await this.tagsService.generateLabelsPDF(
          this.productProcess.ProductsUp4,
          this.productProcess.ProductsUp16,
          this.productProcess.ProductsUp80,
          this.TagTemplate
        );

        if (status === 200) {
          this.pdf_loading = false;
          this.commonService.showSuccessMessage("Etiquetas generadas correctamente");
        } else {
          // Maneja el error si el status no es 200
          this.commonService.showErrorMessage("Error al generar etiquetas");
          this.pdf_loading = false;
        }
      } catch (error) {
        // Captura y maneja errores de la promesa
        this.commonService.showErrorMessage("Ocurrió un error durante la generación de etiquetas");
        this.pdf_loading = false;
      }
    }

  //Buscar por ID
  OnSearchID(): void {
    if (!this.productId || this.quantityOption === null || this.quantityTags === 0 || !this.TagTemplate) {
      this.commonService.showWarningMessage("Por favor complete los campos");
      return;
    }
  
    const ModuleName = "outlet"
    this.loading = true;
  
    this.productProcess.SearchID(this.productId, this.quantityOption, this.quantityTags, ModuleName, this.TagTemplate).pipe(takeUntil(this.destroy$)).subscribe({
      next: () => {
        this.loading = false
        this.commonService.showSuccessMessage("Producto Agregado");
        // Limpiar campos
        this.productId = "";
        this.quantityOption = "";
        this.quantityTags = 1;
        this.cd.detectChanges();
      },
      error: (error) => {
        this.loading = false;
        const message = error.status === 404
          ? "ID no encontrado"
          : "Ocurrió un error, inténtelo de nuevo";
        this.snackBar.open(message, "Cerrar", {
          duration: 3000,
          verticalPosition: "top",
        });
      }
    });
  }
  
  //Mostrar productos del arreglo
  show(listType: string) {
    let headerTitle: string = '';
  
    switch (listType) {
      case 'up4':
        headerTitle = 'Etiquetas Up 4';
        break;
      case 'up16':
        headerTitle = 'Etiquetas Up 16';
        break;
      case 'up80':
        headerTitle = 'Etiquetas Up 80';
        break;
      default:
        this.snackBar.open("Tipo de lista no válido", "Cerrar", {
          duration: 3000,
          verticalPosition: "top",
        });
        return;
    }
  
    this.ref = this.dialogService.open(TagOutletProductsComponent, {
      data: {
        listType: listType
      },
      header: headerTitle,
      width: '50%'
    });
  
    /* console.log('Datos enviados al diálogo:', {
      products: dataToShow,
      listType: listType
    }); */
  }

}
