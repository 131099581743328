import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';
import { item } from '../../prices.enum';

@Component({
  selector: 'app-update-prices-edit',
  templateUrl: './update-prices-edit.component.html',
  styleUrls: ['./update-prices-edit.component.css']
})
export class UpdatePricesEditComponent implements OnInit {

  selectedItem: item = {} as item;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public commonService: CommonService
  ) {this.selectedItem = { ...this.config.data.selectedItem }}

  ngOnInit(): void {
  }
  
  save() {
    this.commonService.showSuccessMessage("Cambios Guardados");
    if (this.selectedItem.newPrice !== undefined && this.selectedItem.oldPrice !== undefined) {
      this.selectedItem.differencePrice = parseFloat((this.selectedItem.newPrice - this.selectedItem.oldPrice).toFixed(2));
    }
    if (this.selectedItem.newCost !== undefined && this.selectedItem.oldCost !== undefined) {
      this.selectedItem.differenceCost = parseFloat((this.selectedItem.newCost - this.selectedItem.oldCost).toFixed(2));
    }
    this.ref.close(this.selectedItem); 
  }

  cancel() {
    this.ref.close(); 
  }

}
