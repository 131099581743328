import { Injectable } from "@angular/core";
import {
  Up4Products,
  Up16Products,
  Up80Products,
  keywordsConfig,
  Up2Products,
} from "./tags.enums";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "src/app/shared/common.service";

@Injectable({
  providedIn: "root",
})
export class TagsProcessProdutService {
  // Interfaces
  public ProductsUp2: Up2Products[] = [];
  public ProductsUp4: Up4Products[] = [];
  public ProductsUp16: Up16Products[] = [];
  public ProductsUp80: Up80Products[] = [];
  
  constructor(private http: HttpClient, private commonService: CommonService) {}

  ngOnInit(): void {}

  public clearAllProducts(): void {
    this.ProductsUp4 = [];
    this.ProductsUp16 = [];
    this.ProductsUp80 = [];
    this.ProductsUp2 = [];
  }

  public filterProductName(productName: string, keywords: string[]): string {
    const words = productName.split(" "); // Dividir el nombre en palabras
    const filteredWords = words.filter((word) =>
      keywords.some((keyword) => word.toLowerCase() === keyword.toLowerCase())
    );
    return filteredWords.join(" "); // Unir las palabras filtradas de nuevo en una cadena
  }

  public processProduct(
    itemData: any
  ): Up4Products | Up16Products | Up80Products | null {
    const Tags = itemData["Etiqueta"] || "";
    const TagsUp2 = "2";
    const TagsUp4 = "4";
    const TagsUp16 = "16";
    const TagsUp80 = "80";

    // Verificar si el producto debe imprimirse
    const imprimir = itemData["Imprimir"] || "No";
    if (imprimir !== "Si" && imprimir !== "Sí" && imprimir !== "si" && imprimir !== "sí" && imprimir !== "yes" && imprimir !== "Yes") {
   /*    console.log(
        `Skipping item '${itemData["Name"]}' because 'Imprimir' is '${imprimir}'`
      ); */
      return null; // Si no debe imprimirse, no procesar el producto
    }

    // Determinar el valor de TagTemplate basado en Location
    const location = itemData["Location"];
    const tagTemplate = (location === "Outlet-Clearance" || location === "Clearance Center (Clearance)") || location === "Clearance" || location === "Clearance Center" ? "Clearance" : "Outlet";
 
    const quantityOnHand = parseInt(itemData["Quantity On Hand"] || "0", 10);
    if (quantityOnHand <= 0) {
      /* console.log(
        `Skipping item '${itemData["Name"]}' because Quantity On Hand is ${quantityOnHand}`
      ); */
      return null; // No procesar si Quantity On Hand es 0 o negativo
    }

    // Filtrar el nombre del producto usando las palabras clave
    const originalProductName = itemData["Name"] || "";

    const productPrice = itemData["Price_New"];
    const productDescription = itemData["Description"]
      ? itemData["Description"].toString()
      : "";

    if (TagsUp2.includes(Tags)) {
      const newUp2Products: Up2Products = {
        productName: originalProductName,
        productId: itemData["Item #"].toString() || "",
        productPrice: productPrice || 0,
        productImage: "",
        quantityTags: quantityOnHand,
      };
      this.ProductsUp2.push(newUp2Products);
      //console.log(`Added product to up2List: ${newUp2Products.productName}`);
    }else if (TagsUp4.includes(Tags)) {
      const filteredProductName = this.filterProductName(
        originalProductName,
        keywordsConfig.up4Keywords
      );
      const newUp4Products: Up4Products = {
        productName: filteredProductName,
        productId: itemData["Item #"].toString() || "",
        productPrice: productPrice || 0,
        productRetail: productDescription || "",
        quantityTags: quantityOnHand,
        TagTemplate: tagTemplate || "",
      };
      this.ProductsUp4.push(newUp4Products);
      //console.log(`Added product to up4List: ${newUp4Products.productName}`);
    } else if (TagsUp16.includes(Tags)) {
      const filteredProductName = this.filterProductName(
        originalProductName,
        keywordsConfig.up16Keywords
      );
      const newUp16Products: Up16Products = {
        productName: originalProductName, //filteredProductName,
        productId: itemData["Item #"].toString() || "",
        productPrice: productPrice || 0,
        productRetail: productDescription || "",
        quantityTags: quantityOnHand,
        Measurements: "",
        TagTemplate: tagTemplate || "",
      };
      this.ProductsUp16.push(newUp16Products);
      //console.log(`Added product to up16List: ${newUp16Products.productName}`);
    } else if (TagsUp80.includes(Tags)) {
      const filteredProductName = this.filterProductName(
        originalProductName,
        keywordsConfig.up80Keywords
      );
      const newUp80Products: Up80Products = {
        productName: filteredProductName,
        productId: itemData["Item #"].toString() || "",
        productPrice: productPrice || 0,
        quantityTags: quantityOnHand || 0,
        TagTemplate: tagTemplate || "",
      };
      this.ProductsUp80.push(newUp80Products);
      //console.log(`Added product to up16List: ${newUp80Products.productName}`);
    } else {
      /* console.log(
        `Item with Category: '${Tags}' did not match any category. Item Name: '${itemData["Name"]}'`
      ); */
    }

    return null;
  }

  public SearchID(
    productId: string,
    TypeOption: string,
    quantityTags: number,
    ModuleName: string,
    TagTemplate: string
  ): Observable<void> {
    const id_sb = productId;
    const store = ModuleName;
    let url = `${environment.endpoint}tags/search/${id_sb}/${store}`;

    if (TypeOption === "Up 4") {
      return this.http.get<Up4Products[]>(url).pipe(
        tap((products) => {
          products.forEach((product) => {
            product.quantityTags = quantityTags;
            product.TagTemplate = TagTemplate
          });
          this.ProductsUp4.push(...products);
          //console.log(products)
        }),
        catchError(this.commonService.handleRequestError),
        map(() => {})
      );
    } else if (TypeOption === "Up 16") {
      return this.http.get<Up16Products[]>(url).pipe(
        tap((products) => {
          products.forEach((product) => {
            product.quantityTags = quantityTags;
            product.TagTemplate = TagTemplate
          });
          this.ProductsUp16.push(...products);
        }),
        catchError(this.commonService.handleRequestError),
        map(() => {})
      );
    } else if (TypeOption === "Up 80") {
      return this.http.get<Up80Products[]>(url).pipe(
        tap((products) => {
          products.forEach((product) => {
            product.quantityTags = quantityTags;
            product.TagTemplate = TagTemplate
          });
          this.ProductsUp80.push(...products);
        }),
        catchError(this.commonService.handleRequestError),
        map(() => {})
      );
    } else if(TypeOption === "Up 2"){
      return this.http.get<Up2Products[]>(url).pipe(
        tap((products) => {
          products.forEach((product) => {
            product.quantityTags = quantityTags;
          });
          this.ProductsUp2.push(...products);
        }),
        catchError(this.commonService.handleRequestError),
        map(() => {})
      );
  } else {
      throw new Error("Invalid Type");
    }
  }

  public getUp2Products(): Up2Products[] {
    return this.ProductsUp2;
  }

  public getUp4Products(): Up4Products[] {
    return this.ProductsUp4;
  }

  public getUp16Products(): Up16Products[] {
    return this.ProductsUp16;
  }

  public getUp80Products(): Up80Products[] {
    return this.ProductsUp80;
  }
}
