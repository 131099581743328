import { Store, Stores } from "./../../../shared/common.enums";
import { csvItem, item, pricesform } from "./../prices.enum";
import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  faCloudUpload,
  faFileCsv,
  faFileDownload,
  faObjectGroup,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import * as moment from "moment";
import { CommonService } from "src/app/shared/common.service";
import { PricesService } from "../prices.service";
import * as Papa from "papaparse";

@Component({
  selector: "app-update-price-list",
  templateUrl: "./update-price-list.component.html",
  styleUrls: ["./update-price-list.component.css"],
})
export class UpdatePriceListComponent implements OnInit {
  formPrices: pricesform[] = [];
  CsvItem: csvItem[] = [];
  csvData: any[] = [];

  itemsOver30: item[] = []
  itemsBelow30: item[] = []

  Data: any[] = [];
  // Variables del formulario
  store: string = "";
  provider: string = "";

  // Propiedades de la tabla
  id: number = 0;
  name: string = "";
  newPrice: number = 0;
  oldPrice: number = 0;
  difference: number = 0;
  loading: boolean = false;
  disableStore = false;

  // utils
  stores = [...Stores];
  filters = {
    date: new FormControl(moment()),
    dateFormated: "",
    store: Store.hds,
  };
  SB_inventory: { [key: string]: File } = {};
  WS_prices: { [key: string]: File } = {};
  Over30_CSV: { [key: string]: File } = {};
  Below30_CSV: { [key: string]: File } = {};

  // iconos
  faPrices = faObjectGroup;
  CSVicon = faFileCsv;
  dowloadIcon = faFileDownload;
  uploadIcon = faCloudUpload;
  loadingIcon = faSpinner;

  constructor(
    public commonService: CommonService,
    private priceService: PricesService
  ) {}

  defaultValues() {
    this.store = "";
    this.provider = "";
    this.formPrices = [];
    this.CsvItem = [];
    this.itemsOver30 = [];
    this.itemsBelow30 = [];
    this.filters = {
      date: new FormControl(moment()),
      dateFormated: "",
      store: Store.hds,
    };
    this.SB_inventory = {};
    this.WS_prices = {};
    this.Over30_CSV = {};
    this.Below30_CSV = {};
  }

  ngOnInit(): void {
    this.onChangeStore();
  }

  onSizeChange() {
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  }

  onChangeStore() {
    this.commonService.currentStore$.next(this.filters.store);
  }

  onInventorySB($event: any) {
    const file = $event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          if (result.data && result.data.length > 0) {
            this.csvData = result.data;
            if (this.validateCsvData(this.csvData, "SB")) {
              this.SB_inventory["SB_inventory"] = file;
            } else {
              this.commonService.showErrorMessage(
                "El archivo seleccionado no es valido, asegurate de que es un inventario valido"
              );
            }
          } else {
            this.commonService.showErrorMessage(
              "El archivo seleccionado puede estar vacio"
            );
          }
        },
        header: true,
      });
    }
  }

  onInventoryWS($event: any) {
    const file = $event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          if (result.data && result.data.length > 0) {
            this.csvData = result.data;
            if (this.validateCsvData(this.csvData, "WS")) {
              this.WS_prices["WS_prices"] = file;
            } else {
              this.commonService.showErrorMessage(
                "El archivo seleccionado no es valido, asegurate de que es un inventario valido"
              );
            }
          } else {
            this.commonService.showErrorMessage(
              "El archivo seleccionado puede estar vacio"
            );
          }
        },
        header: true,
      });
    }
  }

  validateCsvData(csvData: any[], caller: "SB" | "WS" | "Over30" | "Below30"): boolean {
    const header = csvData[0]; 

    const requiredFieldsSB = [
      "SKU",
      "Item #",
      "Name",
      "Price",
      "Cost",
      "Quantity On Hand",
    ];
    const requiredFieldsWS = ["Name", "SKU", "Product ID", "Cost", "Price"];

    const requiereFieldsOver30 = ["Item #", "Name", "SKU", "Cost_Old","Cost_New", "Cost_Change","Price_Old", "Price_New", "Price_Change", "Quantity On Hand", "Store", "ID_SB"];
    
    const requiredFieldsBelow30 = ["Item #", "Name", "SKU", "Cost_Old","Cost_New", "Cost_Change","Price_Old", "Price_New", "Price_Change", "Quantity On Hand", "Store", "ID_SB"];

    if (caller === "SB") {
      return requiredFieldsSB.every((field) => field in header);
    } else if (caller === "WS") {
      return requiredFieldsWS.every((field) => field in header);
    } else if (caller === "Over30") {
      return requiereFieldsOver30.every((field) => field in header);
    } else if (caller === "Below30") {
      return requiredFieldsBelow30.every((field) => field in header);
    }
    return false;
  }

  onWSPricelist() {
    //console.log(this.store);
    this.loading = true;
    this.disableStore = true;
    //console.log(this.store)
    if (!this.provider) {
      this.commonService.showErrorMessage("Por favor selecciona un proveedor");
      this.loading = false;
      return;
    }

    this.priceService.getItems(this.provider).subscribe(
      (response) => {
        // Crear un Blob con la respuesta (archivo CSV)
        const blob = new Blob([response], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Crear un enlace para descargar el archivo
        const a = document.createElement("a");
        a.href = url;
        a.download = `${this.provider}.csv`;
        document.body.appendChild(a);
        a.click();

        // Limpiar después de la descarga
        window.URL.revokeObjectURL(url);
        a.remove();

        this.loading = false; // Dejar de cargar
      },
      (error) => {
        this.commonService.showErrorMessage(
          "Error al generar el archivo",
          error
        );
        this.loading = false;
      }
    );
  }

  onDiscrepancies() {
    if (!this.SB_inventory["SB_inventory"] || !this.WS_prices["WS_prices"]) {
      this.commonService.showErrorMessage("Por favor, carga ambos archivos CSV");
      return;
    }
  
    this.loading = true;
  
    const parseFile = (file: File): Promise<any[]> => {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          complete: (result) => {
            if (result.data && result.data.length > 0) {
              resolve(result.data);
            } else {
              reject("El archivo seleccionado puede estar vacío");
            }
          },
          header: true,
        });
      });
    };
  
    Promise.all([
      parseFile(this.SB_inventory["SB_inventory"]),
      parseFile(this.WS_prices["WS_prices"]),
    ])
      .then(async ([sbData, wsData]) => {
        const discrepancies = sbData.map((sbItem) => {
          const wsItem = wsData.find((ws) => ws.SKU === sbItem?.SKU); // Usar operador opcional
          if (wsItem && sbItem) {
            const costOld = parseFloat(sbItem.Cost);
            const costNew = parseFloat(wsItem.Cost);
            const priceOld = parseFloat(sbItem.Price);
            const priceNew = parseFloat(wsItem.Price);
            const Stock = parseFloat(sbItem["Quantity On Hand"]);
  
            const costChange = ((costNew - costOld) / costOld) * 100;
            const priceChange = ((priceNew - priceOld) / priceOld) * 100;
  
            const ID_SB = sbItem["Item ID"]
            return {
              "Item #": sbItem["Item #"],
              Name: sbItem.Name,
              SKU: wsItem.SKU,
              Cost_Old: costOld,
              Cost_New: costNew,
              Cost_Change: costChange.toFixed(2),
              Price_Old: priceOld,
              Price_New: priceNew,
              Price_Change: priceChange.toFixed(2),
              "Quantity On Hand": Stock,
              Store: this.store,
              ID_SB: ID_SB,
              "Imprimir": "",
              "Etiqueta": "",
              "Location": "",
              "Description": "",
            };
          }
          return undefined; // Devolver undefined si no hay coincidencia
        }).filter(Boolean);  // Filtrar los undefined
  
        // Dividir en dos arreglos
        const minorChanges = discrepancies.filter(item => 
          item && Math.abs(parseFloat(item.Cost_Change)) < 30 && Math.abs(parseFloat(item.Price_Change)) < 30 && (parseFloat(item.Cost_Change) !== 0 || parseFloat(item.Price_Change) !== 0)
        );
  
        const majorChanges = discrepancies.filter(item => 
          item && (Math.abs(parseFloat(item.Cost_Change)) >= 30 || Math.abs(parseFloat(item.Price_Change)) >= 30)
        );
  
        this.loading = false;
        //console.log("Artículos con cambios menores al 30%:", minorChanges);
        //console.log("Artículos con cambios mayores o iguales al 30%:", majorChanges);
        this.commonService.showSuccessMessage("Discrepancias encontradas y procesadas");

        await this.generateCsv(minorChanges, "Cambios Menores a 30.csv");
        await this.generateCsv(majorChanges, "Cambios Mayores a 30.csv");

        return { minorChanges, majorChanges };

      })
      .catch((error) => {
        this.loading = false;
        this.commonService.showErrorMessage(error);
      });

      this.SB_inventory = {};
      this.WS_prices = {};
  }
  

   async generateCsv(data: any[], fileName: string) {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    a.remove();
  }

  Over30($event: any) {
    const file = $event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          if (result.data && result.data.length > 0) {
            this.csvData = result.data;
            if (this.validateCsvData(this.csvData, "Over30")) {
              this.Over30_CSV["Over30"] = file;
              this.addItemsFromCsv(this.csvData, "Over30");
              this.addItemsFromData(this.itemsOver30);
            } else {
              this.commonService.showErrorMessage(
                "El archivo seleccionado no es valido, asegurate de que es un inventario valido"
              );
            }
          } else {
            this.commonService.showErrorMessage(
              "El archivo seleccionado puede estar vacio"
            );
          }
        },
        header: true,
      });
    }
  }

  Below30($event: any){
    const file = $event.target.files[0];
    if (file){
      Papa.parse(file, {
        complete: (result) => {
          if (result.data && result.data.length > 0){
            this.csvData = result.data;
            if (this.validateCsvData(this.csvData, "Below30")){
              this.Below30_CSV["Below30"] = file;
              this.addItemsFromCsv(this.csvData, "Below30");
              this.addItemsFromData(this.itemsBelow30);
            } else {
              this.commonService.showErrorMessage("El archivo seleccionado no es valido, asegurate de que es un inventario valido");
            }
          }else{
            this.commonService.showErrorMessage("El archivo seleccionado puede estar vacio");
          }
        },
        header: true
      });
    } 
  }

  addItemsFromCsv(csvData: any[], caller: "Over30" | "Below30") {
    const newItems = csvData.map((data) => ({
      id: data["Item #"],
      name: data.Name,
      newPrice: parseFloat(data.Price_New),
      oldPrice: parseFloat(data.Price_Old),
      differencePrice: parseFloat(data.Price_Change), 
      oldCost: parseFloat(data.Cost_Old),
      newCost: parseFloat(data.Cost_New),
      differenceCost: parseFloat(data.Cost_Change),
      Store: data.Store,
      ID_SB: data.ID_SB
    }));

    if(caller === "Over30"){
      this.itemsOver30 = [...this.itemsOver30, ...newItems as item[]];
    }else if(caller === "Below30"){
      this.itemsBelow30 = [...this.itemsBelow30, ...newItems as item[]];
    }
  }

  addItemsFromData(data: item[]) {
    const newItem = data.map((item) => ({
      id: item.id === "" ? 0 : item.id,
      name: item.name === "" ? "Sin Nombre" : item.name,
      newPrice: item.newPrice === 0 ? 0 : item.newPrice,
      oldPrice: item.oldPrice === 0 ? 0 : item.oldPrice,
      differencePrice: item.differencePrice === 0 ? 0 : item.differencePrice,
      oldCost: item.oldCost === 0 ? 0 : item.oldCost,
      newCost: item.newCost === 0 ? 0 : item.newCost,
      differenceCost: item.differenceCost === 0 ? 0 : item.differenceCost,
      Store: item.Store,
      ID_SB: item.ID_SB === "" ? "Sin ID" : item.ID_SB
    }))
    this.Data = [...this.Data, ...newItem];
  }

  onUpdatePrices() {
    if(this.Data.length === 0){
      this.commonService.showErrorMessage("No hay precios para actualizar");
      return;
    }

    this.commonService.showInfoMessage(
      "Los precios se están actualizando, esto puede tardar unos minutos, no cierres la ventana"
    );
    
    this.loading = true;
    console.log(this.Data)
    this.priceService.updatePrices(this.Data, this.store).subscribe(
      (response) => {
        if(response){
          this.commonService.showSuccessMessage("Productos actualizados correctamente");
          this.loading = false;
          this.defaultValues();
          this.defaultValues();
        }else{
          this.commonService.showErrorMessage("Error al actualizar productos");
          this.loading = false;
          this.defaultValues();
        }
      },
      (error) => {
        console.log(error);
        this.commonService.showErrorMessage("Error al actualizar productos");
        this.loading = false;
        this.defaultValues();
      }
    );
  }
}