import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';
import { SalesAddressService } from '../../../sales-address.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sales-clientes-address-personalizada',
  templateUrl: './sales-clientes-address-personalizada.component.html',
  styleUrls: ['./sales-clientes-address-personalizada.component.css']
})
export class SalesClientesAddressPersonalizadaComponent implements OnInit {

  AddressForm: FormGroup = this.fb.group({})
  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    public config: DynamicDialogConfig,
    public commonService: CommonService,
    public addresService: SalesAddressService,
    public ref: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.AddressForm =this.fb.group({
      address: ['', [Validators.required, Validators.maxLength(255)]]
    })
  }
  
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.ref.close(false)
  }

  onSubmit(){
    console.log("Formulario", this.AddressForm.value)
    if(this.AddressForm.valid){
      const data = {
        address: this.AddressForm.value.address,
        idCliente: this.config.data
      }
      this.addresService.addPersonalizadaAddress(data).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (res)=>{
          this.commonService.showSuccessMessage(res.msg)
          this.AddressForm.reset({
            address: ''
          })
          this.ref.close(true)
        },(err)=>{
          this.commonService.showErrorMessage(err)
          this.ref.close(false)
        }
      )
    }
  }
}
