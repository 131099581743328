import { Component, OnInit } from '@angular/core';
import { SalesService } from '../../sales.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/common.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-sales-clientes-seguimiento-edit',
  templateUrl: './sales-clientes-seguimiento-edit.component.html',
  styleUrls: ['./sales-clientes-seguimiento-edit.component.css']
})
export class SalesClientesSeguimientoEditComponent implements OnInit {
  private destroy$ = new Subject();
  pasos: any

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    public salesServices:SalesService,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.getPasobyFollwing()
  }

  getPasobyFollwing(){
    this.salesServices.getPasobyFollowing().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.pasos = res.msg
      },err=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
