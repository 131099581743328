import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SalesService } from '../../sales.service';
import { CommonService } from 'src/app/shared/common.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SalesClientesAddressDesarrolloComponent } from './sales-clientes-address-desarrollo/sales-clientes-address-desarrollo.component';
import { SalesClientesAddressPersonalizadaComponent } from './sales-clientes-address-personalizada/sales-clientes-address-personalizada.component';
import { SalesClientesAddressLocalComponent } from './sales-clientes-address-local/sales-clientes-address-local.component';
import { SalesClientesAddressForaneaComponent } from './sales-clientes-address-foranea/sales-clientes-address-foranea.component';
import { SalesAddressService } from '../../sales-address.service';
import { SalesClientesAddressViewClientesComponent } from './sales-clientes-address-view-clientes/sales-clientes-address-view-clientes.component';
import { Address, AddressCasaDesarrollo, Addressdesarrollo, AddressForanea, AddressLocal, AddressPersonalizada, AddressResidencial, addressText, AddressVilla, TipeAddressText } from '../../sales-search/address.enum';
import { FormBuilder } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AlertV2Component } from 'src/app/shared/alert-v2/alert-v2.component';
import { AlertService, ConfirmationAlert } from 'src/app/shared/alert/alert.service';

@Component({
  selector: 'app-sales-clientes-address',
  templateUrl: './sales-clientes-address.component.html',
  styleUrls: ['./sales-clientes-address.component.css']
})
export class SalesClientesAddressComponent implements OnInit {
  
  @ViewChild('alert') alert!: AlertV2Component;

  searchSubscription?: Subscription;

  inputSearch = ''

  idAddressSelected = 0

  faSearch = faSearch

  //villa
  villa: AddressVilla[] = []
  villaSearch: AddressVilla[] = []
  //desarrollo
  desarrollo : Addressdesarrollo[] = []
  desarrolloSearch: Addressdesarrollo[] = []
  //casa_desarrollo
  casa: AddressCasaDesarrollo[] = []
  casaSearch: AddressCasaDesarrollo[]=[]
  //local
  local : AddressLocal[] = []
  localSearch: AddressLocal[] = []
  //personalizada 
  personalizada: AddressPersonalizada[] = []
  personalizadaSearch: AddressPersonalizada[] = []
  //foranea
  foranea: AddressForanea[] = []
  foraneaSearch: AddressForanea[] = []
  //residencial
  residencial: AddressResidencial[] = []
  residencialSearch: AddressResidencial[] = []

  AddressesText: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([])
  TipeAddressText: TipeAddressText[] = []
  AddressesTextDesarrollo: addressText[] = []
  AddressesTextCasaDesarrollo: addressText[] = []
  AddressesTextLocal: addressText[] = []
  AddressesTextPersonalizada: addressText[] = []
  AddressesTextResidencial: addressText[] = []
  AddressesTextForanea: addressText[] = []
  AddressesTextVilla: addressText[] = []

  addressesSearch: any[] = []

  stateForm = this.fb.group({})
  
  constructor(
    public config: DynamicDialogConfig,
    public salesService: SalesService,
    public dialogService: DialogService,
    public commonService: CommonService,
    public addressService: SalesAddressService,
    public fb: FormBuilder,
    public alertService: AlertService
  ) {
    this.stateForm = this.fb.group({
      text: ['']
    })
  }

  ngOnInit(): void {
    this.getAddresses()
    this.getSearchAddresses()

    this.stateForm.get('text')?.valueChanges.pipe(
      startWith(''),
      map(value => this.filterAddresses(value))
    ).subscribe(filteredAddresses => {
      this.AddressesText.next(filteredAddresses)
    })    
  }

  resetValues(){
    this.desarrollo = []
    this.local = []
    this.personalizada = []
    this.foranea = []
    this.villa = []
    this.casa = []
    this.residencial = []
  }

  getSearchAddresses(){
    this.addressService.getAddresses().pipe().subscribe(
      (addresses)=>{
        for(let address of addresses){
          switch(address.tipo){
            case 'desarrollo':
              this.desarrolloSearch.push(address);
              this.buildAddressText(address)
              break
            case 'casa_desarrollo':
              this.casaSearch.push(address)
              this.buildAddressText(address)
              break
            case 'local':
              this.localSearch.push(address)
              this.buildAddressText(address)
              break
            case 'personalizada':
              this.personalizadaSearch.push(address)
              this.buildAddressText(address)
              break
            case 'residencial':
              this.residencialSearch.push(address)
              this.buildAddressText(address)
              break
            case 'foranea':
              this.foraneaSearch.push(address)
              this.buildAddressText(address)
              break
            case 'villa':
              this.villaSearch.push(address)
              this.buildAddressText(address)
              break
          }
        }
        this.TipeAddressText.push({tipo:'Desarrollos', address: this.AddressesTextDesarrollo})
        this.TipeAddressText.push({tipo:'Casas en desarrollos', address: this.AddressesTextCasaDesarrollo})
        this.TipeAddressText.push({tipo:'Locales', address: this.AddressesTextLocal})
        this.TipeAddressText.push({tipo:'Personalizadas', address: this.AddressesTextPersonalizada})
        this.TipeAddressText.push({tipo:'Residenciales', address: this.AddressesTextResidencial})
        this.TipeAddressText.push({tipo:'Foraneas', address: this.AddressesTextForanea})
        this.TipeAddressText.push({tipo:'Villas', address: this.AddressesTextVilla})
      },(err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  getAddresses(){
    this.resetValues()
    this.addressService.getClienteAddresses(this.config.data.id).subscribe(
      (addresses)=>{
        for(let address of addresses){
          switch(address.tipo){
            case 'desarrollo':
              this.desarrollo.push(address);
              break
            case 'casa_desarrollo':
              this.casa.push(address)
              break
            case 'local':
              this.local.push(address)
              break
            case 'personalizada':
              this.personalizada.push(address)
              break
            case 'residencial':
              this.residencial.push(address)
              break
            case 'foranea':
              this.foranea.push(address)
              break
            case 'villa':
              this.villa.push(address)
              break
          }
        }
      },(err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }
  ViewAddDesarrollos(){
    const ref = this.dialogService.open(SalesClientesAddressDesarrolloComponent, {
      header: `Agregar direccion de desarrollo a ${this.config.data.nombre}`,
      width: "50%",
      height: "100%",
      data: this.config.data.id
    })
    ref.onClose.subscribe( 
      (status: boolean)=>{
        if(status === true){
          this.getAddresses()
        }
      }
    )
  }

  ViewAddForanea(){
    const ref = this.dialogService.open(SalesClientesAddressForaneaComponent, {
      header: `Agregar dirección Foranea a cliente ${this.config.data.nombre}`,
      width: "50%",
      data: this.config.data.id
    })
    ref.onClose.subscribe( 
      (status: boolean)=>{
        if(status === true){
          this.getAddresses()
        }
      }
    )
  }

  ViewAddLocal(){
    const ref = this.dialogService.open(SalesClientesAddressLocalComponent, {
      header:`Agregar dirección Local a cliente ${this.config.data.nombre}`,
      width: "50%",
      data: this.config.data.id
    })
    ref.onClose.subscribe( 
      (status: boolean)=>{
        if(status === true){
          this.getAddresses()
        }
      }
    )
  }

  ViewAddPersonalizada(){
    const ref = this.dialogService.open(SalesClientesAddressPersonalizadaComponent,{
      header: `Agregar direccion personalizada a ${this.config.data.nombre}`,
      width: "50%",
      data: this.config.data.id
    })
    ref.onClose.subscribe( 
      (status: boolean)=>{
        if(status === true){
          this.getAddresses()
        }
      }
    )
  }

  viewAddress(id: number){
    this.dialogService.open(SalesClientesAddressViewClientesComponent, {
      header:"Clientes en la dirección",
      width:"50%",
      data: id
    })
  }

  buildAddressText(address: Address){
    let text = ""
    switch(address.tipo){
    case 'desarrollo':
      text += `Desarrollo: ` + address.desarrollo?.nombre
      text += ` / Edificio: ` + address.edificio?.nombre_edificio
      text += ` / Condominio: ` + address.condominio?.nombre_condominio
      text += ` / Habitaciones: ` + address.condominio?.numero_de_habitaciones
      this.AddressesTextDesarrollo.push({id: address.id, text: text})
    break
    case 'casa_desarrollo':
      text += `Desarrollo: ` + address.desarrollo?.nombre
      text += ` / Casa: ` + address.casa
      this.AddressesTextCasaDesarrollo.push({id: address.id, text: text})
    break
    case 'local':
      text += `Colonia: ` + address.colonia?.nombre
      text += ` / Calle: ` + address.calle?.nombre
      text += ` / Casa: ` + address.casa?.nombre
      text += ` / Entre Calle 1: ` + address.entre_calle_1?.nombre
      text += ` / Entre Calle 2: ` + address.entre_calle_2?.nombre
      this.AddressesTextLocal.push({id: address.id, text: text})
    break
    case 'personalizada':
      text += `Dirección: ` + address.address.direccion
      this.AddressesTextPersonalizada.push({id: address.id, text: text})
    break
    case 'residencial':
      text += `Residencia: ` + address.recidencial
    
      if (address.aggregates && address.aggregates.length > 0) {
        const aggregatesText = address.aggregates.map(aggregate => [`${aggregate.Tipo}`+' : '+aggregate.contenido]).join(' / ')
        text += `/`+ aggregatesText;
      }

      text = text.trim()
      this.AddressesTextResidencial.push({id: address.id, text: text})
    break
    case 'foranea':
      text += `Ciudad: ` + address.ciudad.nombre
      text += ` / Colonia: ` + address.colonia?.nombre
      text += ` / Calle: ` + address.calle?.nombre
      text += ` / Casa: ` + address.casa?.nombre
      text += ` / Entre Calle 1: ` + address.entre_calle_1?.nombre
      text += ` / Entre Calle 2: ` + address.entre_calle_2?.nombre

      this.AddressesTextForanea.push({id: address.id, text: text})
    break
    case 'villa':
      text += `Desarrollo: ` + address.desarrollo?.nombre
      text += ` / Villa: ` + address.villa
      this.AddressesTextVilla.push({id: address.id, text: text})
    break
    }
  }

  onOptionSelected(event: any): void {
    // Elimina las etiquetas HTML del texto seleccionado
    const sanitizedText = this.stripHtml(event.option.value);
    this.stateForm.get('text')?.setValue(sanitizedText);
  }

  OnselectedAddress(id: number){
    this.idAddressSelected = id
  }

  OnAddAddressToCliente(){
    const data = {
      idAddress: this.idAddressSelected,
      idCliente: this.config.data.id
    } 
    this.addressService.AddAddressToCliente(data).subscribe(
      res=>{
        this.commonService.showSuccessMessage(res.msg)
        this.idAddressSelected = 0
        this.stateForm.reset({
          text: ''  
        })
        this.getAddresses()
      },err=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  OnDeleteAddressToCliente(id: number){
    const data = {
      idAddress: id,
      idCliente: this.config.data.id
    }
    const body = `
    <h2>¿Seguro que quiere eliminar la dirección del cliente?</h2>
    <br>
    <p>Podrá volver a asignar la dirección al cliente en cualquier otro momento</p>
    `
    const confirmationData: ConfirmationAlert = {
            title: 'Eliminar dirección del cliente',
            body: body,
            cancelLabel: "Cerrar",
            confirmLabel: 'Eliminar',
            confirmDanger: true,
            onConfirm: () => {
              this.DeleteAddressToCliente(data)
            }
          }
    this.alertService.setConfirmationAlertv2(this.alert, confirmationData)
  }

  DeleteAddressToCliente(data: any){
    this.addressService.DeleteAddressToCliente(data).subscribe(
      res=>{
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showSuccessMessage(res.msg)
        this.getAddresses()
      },err=>{
        this.alertService.finishConfirmationAlertv2(this.alert!)
        this.commonService.showErrorMessage(err)
        this.getAddresses()
      }
    )
  }

  private stripHtml(html: string): string {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    return tempDiv.textContent || tempDiv.innerText || ''
  }

  private filterAddresses(value: string): any[] {
    const filterValue = value.toLowerCase()
  
    // Función para resaltar coincidencias
    const highlightText = (text: string, searchValue: string): string => {
      const regex = new RegExp(`(${searchValue})`, 'gi') // 'g' para global, 'i' para insensible a mayúsculas
      return text.replace(regex, `<strong>$1</strong>`) // Resalta las coincidencias
    }
  
    // Filtrar y resaltar
    return this.TipeAddressText.map(group => ({
      tipo: group.tipo,
      address: group.address
        .filter(item => item.text.toLowerCase().includes(filterValue)) // Filtrar por coincidencias
        .map(item => ({
          ...item,
          text: highlightText(item.text, value) // Resaltar coincidencias en el texto
        }))
    })).filter(group => group.address.length > 0)
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe()
  }
  
}
