import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CommonService } from 'src/app/shared/common.service';
import { TagsEditProductComponent } from '../../tags-edit-product/tags-edit-product.component';
import { TagsProcessProdutService } from '../../tags-process-product.service';
import { Up4Products, Up16Products, Up80Products, Up2Products } from '../../tags.enums';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tag-hds-products',
  templateUrl: './tag-hds-products.component.html',
  styleUrls: ['./tag-hds-products.component.css'],
  providers: [DialogService]
})
export class TagHdsProductsComponent implements OnInit {

  private destroy$ = new Subject();

  ref: DynamicDialogRef | undefined;

  filteredList: any[] = []; // Lista filtrada que se mostrará en la vista
  
  constructor(
    public config: DynamicDialogConfig, 
    public dialogService: DialogService,
    private productProcess: TagsProcessProdutService,
    public commonService: CommonService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    // Llenar las listas desde el servicio
    this.productProcess.getUp2Products();
    this.productProcess.getUp4Products();
    this.productProcess.getUp16Products();
    this.productProcess.getUp80Products();

    // Filtrar la lista según el tipo de lista recibido
    this.filterList();
  }

  filterList() {
    const listType = this.config.data?.listType;
    if (listType === "up4") {
      this.filteredList = this.productProcess.ProductsUp4;
    } else if (listType === "up16") {
      this.filteredList = this.productProcess.ProductsUp16;
    } else if (listType === "up80") {
      this.filteredList = this.productProcess.ProductsUp80;
    }else if (listType === "up2") {
      this.filteredList = this.productProcess.ProductsUp2;
    }
    this.cd.detectChanges();
  } 

  editProduct(product: any, index: number): void {
    this.ref = this.dialogService.open(TagsEditProductComponent, {
      data: { product },
      header: 'Editar Producto',
      width: '50%'
    });

    this.ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((updatedProduct) => {
      if (updatedProduct) {
        // Actualizar el producto en la lista filtrada
        this.filteredList[index] = updatedProduct; // Reemplaza el producto actualizado
      }
    });
  }

  deleteProduct(index: number): void {
    const listType = this.config.data?.listType;
    if (listType === "up4") {
      this.productProcess.ProductsUp4.splice(index, 1);
      this.filteredList = [...this.productProcess.ProductsUp4]; // Actualiza la lista filtrada
    } else if (listType === "up16") {
      this.productProcess.ProductsUp16.splice(index, 1);
      this.filteredList = [...this.productProcess.ProductsUp16]; // Actualiza la lista filtrada
    } else if (listType === "up80") {
      this.productProcess.ProductsUp80.splice(index, 1);
      this.filteredList = [...this.productProcess.ProductsUp80]; // Actualiza la lista filtrada
    } else if (listType === "up2") {
      this.productProcess.ProductsUp2.splice(index, 1);
      this.filteredList = [...this.productProcess.ProductsUp2]; // Actualiza la lista filtrada
    }
    this.commonService.showSuccessMessage(`Producto Eliminado`);
  }

  deleteAllProducts(): void {

    const listType = this.config.data?.listType;
  
    if (listType === "up4") {
      this.productProcess.ProductsUp4 = []; // Vaciar la lista de productos up4
      this.filteredList = []; // Actualizar la lista filtrada
    } else if (listType === "up16") {
      this.productProcess.ProductsUp16 = []; // Vaciar la lista de productos up16
      this.filteredList = []; // Actualizar la lista filtrada
    } else if (listType === "up80") {
      this.productProcess.ProductsUp80 = []; // Vaciar la lista de productos up80
      this.filteredList = []; // Actualizar la lista filtrada
    } else if (listType === "up2") {
      this.productProcess.ProductsUp2 = []; // Vaciar la lista de productos up2
      this.filteredList = []; // Actualizar la lista filtrada
    }
    this.cd.detectChanges();
    this.commonService.showSuccessMessage(`Todos los productos han sido eliminados`);
  }
  
}
