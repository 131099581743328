import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { SalesAddressService } from '../../sales-address.service';
import { CommonService } from 'src/app/shared/common.service';
import { takeUntil } from 'rxjs/operators';
import { BaseType, residencia } from '../../sales-search/address.enum';

@Component({
  selector: 'app-sales-config-address-recidenciales',
  templateUrl: './sales-config-address-recidenciales.component.html',
  styleUrls: ['./sales-config-address-recidenciales.component.css']
})
export class SalesConfigAddressRecidencialesComponent implements OnInit {

  private destroy$ = new Subject<void>(); 
  
  loading = false

  UpdateNombre = 'nombre'
  UpdateStatus = 'status'
  
  recidenciales: residencia[]=[]
  
  RecidenciaInput = ''

  TempRecidenciaInput = ''

  recidenciaId = 0 

  TempName = ''

  RecidencialForm = this.fb.group({})

  constructor(
    public addressService: SalesAddressService,
    public commonService: CommonService,
    public fb: FormBuilder
  ) { 
    this.RecidencialForm =this.fb.group({
      Residencia: ['' , Validators.required]
    })
  }

  ngOnInit(): void {
    this.loading = true
    this.getRecidencia()
    this.loading = false
  }

  getRecidencia(){
    this.addressService.getResidenciales().pipe(takeUntil(this.destroy$)).subscribe(
      (res)=>{
        this.recidenciales = res
      },(err)=>{
        this.commonService.showErrorMessage(err)
      }
    )
  }

  addRecidenciales(){
    const data = {
      nombre: this.RecidencialForm.value.Residencia
    }

    this.addressService.addResidecial(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        this.commonService.showSuccessMessage(res.msg)
        this.RecidencialForm.reset()
        this.getRecidencia()
      },err => {
        this.commonService.showErrorMessage(err)
      }
    )
  }

  OnEdit(values: BaseType){
    this.recidenciaId = values.id
    this.TempName = values.nombre
  }

  EditRecidencial(values: residencia, list: residencia[]){
    this.addressService.editResidencia(values).pipe(takeUntil(this.destroy$)).subscribe(
      res=>{
        this.commonService.showSuccessMessage(res.msg)
        const item = list.find((item) => item.id === values.id)
        if(item){
          item.nombre = values.nombre
        }
        this.reset()
      }, err => {

      }
    )
    return
  }

  EstatusRecidencia(event: any, recidencia: residencia){
    const previusStatus = recidencia.status

    recidencia.status = event.checked ? "Activo" : "Inactivo"
    const data = {
      id: recidencia.id,
      status: recidencia.status
    }

    this.addressService.statusResidencia(data).pipe(takeUntil(this.destroy$)).subscribe(
      res => {

      }, err => {
        this.commonService.showErrorMessage(err)
        recidencia.status = previusStatus
        event.source.checked === "Activo"
      }
    )
  }

  onToggleChange(event: any, tipo: BaseType){
    const previusStatus = tipo.status

    tipo.status = event.checked ? "Activo" : "Inactivo"
    const data = {
      id: tipo.id,
      value: tipo.status
    }

    this.addressService.update(this.UpdateStatus, data).pipe(takeUntil(this.destroy$)).subscribe(
      res=>{},err=>{
        this.commonService.showErrorMessage(err)
        tipo.status = previusStatus,
        event.source.checked === "Activo"
      }
    )
  }

  reset(){
    this.RecidenciaInput = ""
    this.recidenciaId = 0
  }
}