import { SalesService } from './../sales.service';
import { CommonService } from 'src/app/shared/common.service';
import { faAddressBook, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Component, OnDestroy, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { Subscription, Subject } from 'rxjs';
import { Store, Stores } from 'src/app/shared/common.enums';
import { DialogService } from 'primeng/dynamicdialog';
import { SalesClientesViewComponent } from './sales-clientes-view/sales-clientes-view.component';
import { SalesClientesAddressComponent } from './sales-clientes-address/sales-clientes-address.component';
import { Cliente } from '../sales.interfaces';
import { SalesClientesUtilComponent } from './sales-clientes-util/sales-clientes-util.component';
import { AuthService } from 'src/app/auth/auth.service';
import { takeUntil } from 'rxjs/operators';
import { SalesClientesCreateComponent } from './sales-clientes-create/sales-clientes-create.component';

@Component({
  selector: 'app-sales-clientes',
  templateUrl: './sales-clientes.component.html',
  styleUrls: ['./sales-clientes.component.css'],
  providers: []
})
export class SalesClientesComponent implements OnInit, OnDestroy {
  
  clientes: Cliente[] = [];
  loading = false;
  private destroy$ = new Subject();

  clientsIcon = faAddressBook;
  loadingIcon = faSpinner;
  filters = {
    date: new FormControl(moment()),
    dateFormated: '',
    store: Store.hds
  };
  stores = [...Stores];
  searchText = "";
  customersNames: string[] = [];
  searchSubscription?: Subscription;
  search$: Subject<any> = new Subject();
  router: any;

  featureAccess = {
    config: false,
  };

  constructor(
    public commonService: CommonService,
    public dialogService: DialogService,
    private salesService: SalesService,
    public authService: AuthService
    ) { }

  ngOnInit(): void {
    this.ClientesTable();
  };

  setFeatureAccess(){
    const access = this.authService.user.value?.access || [];
    this.featureAccess.config = access.includes("config:view");
  }

  ngOnDestroy(): void {
    this.searchSubscription?.unsubscribe();
  }
  
  onSizeChange(){
    const option = !this.commonService.fullSize$.value;
    this.commonService.fullSize$.next(option);
  };

  ClientesTable() {
    this.loading = true;
    this.salesService.getClientes().pipe(takeUntil(this.destroy$)).subscribe(
      (clientes) => {
        this.clientes = clientes.map(cliente => ({
          ...cliente,
        }));
      },(error)=>{
        this.commonService.showErrorMessage(error);
 });
    this.loading = false;
  };

  getSeverity(status: string) {
    switch (status) {
        case 'Vencido':
            return 'danger';
        case 'Sin iniciar':
            return "success";
        case 'Enviado':
            return 'primary';
        case 'Proximo a vencer':
            return 'warning';
        default:
            return 'unknown';
    }
  };
  
  showClient(id: number, nombre: string, seguimiento: string) {
    const ref = this.dialogService.open(SalesClientesViewComponent, {
      header: `Cliente (${nombre})`,
      width: '70%',
      data: {id, nombre, seguimiento, button: true} 
    });
    ref.onClose.pipe(
      takeUntil(this.destroy$)
    ).pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.ClientesTable();
    });
  };

  showAddress(id: number, nombre: string) {
   this.dialogService.open(SalesClientesAddressComponent, {
      header: `Dirección(es) de ${nombre}`,
      width: '70%',
      data: {id, nombre}
    })
  }

  showAddViews(){
    const ref = this.dialogService.open(SalesClientesCreateComponent, {
      header: `Agregar Cliente`,
      width: '70%',
    });
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.ClientesTable();
    });
  };

/*   showFollingEditView(cliente: any){
    const ref = this.dialogService.open(SalesClientesSeguimientoEditComponent, {
      header: 'Editar Following del cliente',
      width:'50%',
      data: cliente
    })
    ref.onClose.subscribe(() => {
      //console.log("se va a cargar la tabla");
        this.ClientesTable();
    });
  }
 */
  updateFollowUP(id: number, idCliente: number, step:number, stepz:number, idFollowing: number, tipo: string, asistenteId: number, idioma: string){
    
    /* console.log("id", id, "idCliente", idCliente, "step", step, "stepz", stepz, "idFollowing", idFollowing, "tipo", tipo, "asistenteId", asistenteId, "idioma", idioma)
    return */
    const newStep = step + 1;

    const ref = this.dialogService.open(SalesClientesUtilComponent, {
      header: `Actualizar seguimiento`,
      width: '80%',
      data: {tipo, idFollowing, step, idioma, idCliente}
    })
    ref.onClose.pipe(takeUntil(this.destroy$)).subscribe((trigger) => {
    if(!trigger){
      this.commonService.showInfoMessage("Se cancelo la acción");
      return;
    }
    
    if(newStep > stepz){
      const data = {
        idFollowUP: id,
        idCliente: idCliente,
        step: step,
        stepz: stepz,
        idFollowing: idFollowing,
        tipo: tipo,
        idAsistente: asistenteId,
        msgId: trigger
      }
      //console.log("se terminara el seguimiento porque ya no hay mas pasos");
      this.salesService.endFollowUP(data).subscribe(
        (response) => {
          this.commonService.showSuccessMessage(response.msg);
          this.ClientesTable();
        },
        (error) => {
          this.commonService.showErrorMessage(error);
        }
      )
    }else if(newStep <= stepz){
      const data = {
        idFollowUP: id,
        idCliente: idCliente,
        step: newStep,
        actualStep: step,
        stepz: stepz,
        idFollowing: idFollowing,
        tipo: tipo,
        idAsistente: asistenteId,
        msgId: trigger
      }
      //console.log("se actualizará el paso");
      this.salesService.updateFollowUP(data).pipe(takeUntil(this.destroy$)).subscribe(
        (response) => {
          this.commonService.showSuccessMessage(response.msg);
          this.ClientesTable();
        },
        (error) => {
          this.commonService.showErrorMessage(error);
        }
      )
    }})
  }
}
